import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_ROW_FULL } from "ui-kit";

import { hexToRgba } from "@sbm/fe-utils";

export const Wrapper = styled.div<{
  $isOutgoing: boolean;
  $currentMessage: boolean;
}>`
  margin: ${({ theme }) => theme.spacing(2, 0)};
  padding: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
  background: ${({ theme, $isOutgoing }) =>
    $isOutgoing
      ? hexToRgba(theme.palette.primary.main, 0.04)
      : hexToRgba(theme.palette.secondary.main, 0.08)};
  border-style: solid;
  border-width: ${({ $currentMessage }) => ($currentMessage ? "1px" : 0)};
  border-color: ${({ theme, $isOutgoing, $currentMessage }) =>
    $isOutgoing
      ? $currentMessage
        ? theme.palette.secondary.main
        : hexToRgba(theme.palette.primary.main, 0.08)
      : $currentMessage
      ? theme.palette.secondary.main
      : hexToRgba(theme.palette.secondary.main, 0.12)};
  border-radius: ${({ theme, $isOutgoing }) =>
    $isOutgoing ? theme.spacing(0, 2, 2, 2) : theme.spacing(2, 0, 2, 2)};
  margin: ${({ theme }) => theme.spacing(3, 6, 3, 0)};
  max-width: 380px;
  .message_description {
    ${FLEX_BETWEEN};
    flex-direction: ${({ $isOutgoing }) =>
      $isOutgoing ? "row-reverse" : "row"};
    padding-top: ${({ theme }) => theme.spacing(2)};
    gap: ${({ theme }) => theme.spacing(4)};
  }
`;

export const MessageOptionIcon = styled.div<{
  $isOutgoing: boolean;
}>`
  ${FLEX_ROW_FULL};
  padding: ${({ theme }) => theme.spacing()};
  border-radius: 100%;
  &:hover {
    background: ${({ theme, $isOutgoing }) =>
      $isOutgoing
        ? hexToRgba(theme.palette.primary.main, 0.04)
        : hexToRgba(theme.palette.secondary.main, 0.08)};
  }
`;
