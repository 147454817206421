import React from "react";
import { useTranslation } from "react-i18next";

import { useDeleteDocumentMutation } from "api";
import { Modal } from "ui-kit";

import { Button } from "@mui/material";

export const DeleteDocumentPopup = ({
  open,
  onClose,
  data: { data },
}: {
  open: boolean;
  onClose: () => void;
  data: { data: { documentId: number } };
}) => {
  const { t } = useTranslation();

  const onSuccess = () => {
    onClose();
  };

  const onError = () => {
    onClose();
  };

  const { mutate: deleteDocument } = useDeleteDocumentMutation(
    onSuccess,
    onError
  );

  const content = {
    title: t("delete_my_document_text"),
    secondaryButton: (
      <Button
        onClick={onClose}
        variant="outlined"
        size="large"
        color="secondary"
      >
        {t("cancel")}
      </Button>
    ),
    mainButton: (
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        size="large"
        onClick={() => {
          deleteDocument(data.documentId);
        }}
      >
        {t("confirm")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
