import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW_FULL } from "ui-kit";

import { Card } from "@mui/material";

export const WidgetWrapper = styled(Card)`
  ${FLEX_COLUMN};

  height: 100%;
  min-height: 200px;

  border-bottom: 4px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ theme }) => theme.spacing(4)};

  box-shadow: ${({ theme }) => theme.shadows[4]};
  padding: ${({ theme }) => theme.spacing(0, 4, 4, 4)};
};
`;

export const WidgetDescriptionWrapper = styled.div`
  ${FLEX_ROW_FULL};
  padding: ${({ theme }) => theme.spacing(4)};
  margin-top: ${({ theme }) => theme.spacing(4)};
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: 16px;
`;

export const WidgetChartWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 0)};
  width: 100%;
  height: 170px;
  min-width: 320px;
`;
