import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "ui-kit";

import { Typography } from "@mui/material";
import { Button } from "@sbm/ui-components";
import { IWidget } from "@types";

import { useMedia } from "../../../hooks";
import {
  WidgetWrapper,
  IconWrapper,
  WidgetHeaderWrapper,
  WidgetDescriptionWrapper,
  LeftSection,
  RightSection,
  WidgetActionWrapper,
} from "./styles";

export const ProjectMainWidget = ({
  mainWidgetData,
}: {
  mainWidgetData: IWidget | undefined;
}) => {
  const { t } = useTranslation("project");
  const [{ lgDown, tabletDown }] = useMedia();

  return (
    <WidgetWrapper>
      <LeftSection $hasShowBg={lgDown || tabletDown}>
        <WidgetHeaderWrapper>
          <div className="widget_icon_title">
            <IconWrapper>
              <Icon name="LibraryBig" size={48} />
            </IconWrapper>
            <Typography
              variant="h6"
              fontWeight={700}
              sx={{ textAlign: "initial" }}
            >
              {mainWidgetData?.widgetName}
            </Typography>
          </div>

          <WidgetActionWrapper>
            <Button variant="contained" size="medium">
              <div className="manage_button">
                {t("manage")}
                <Icon name={"ArrowRight"} />
              </div>
            </Button>
          </WidgetActionWrapper>
        </WidgetHeaderWrapper>

        <WidgetDescriptionWrapper>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ minHeight: 53 }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
            ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
        </WidgetDescriptionWrapper>
      </LeftSection>

      {lgDown || tabletDown ? null : <RightSection />}
    </WidgetWrapper>
  );
};
