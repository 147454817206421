import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { EventTypeEnum, useAppSelector, useEffectOnce, useSocket } from "app";
import { DotsLoader, TypingAnimation } from "ui-kit";

import { Stack, Typography } from "@mui/material";
import { ConversationResponse } from "@types";

import { ConversationCard } from "../../ConversationCard";
import { ConversationWrapper } from "../styles";

interface Props {
  conversationData?: ConversationResponse[];
  authorUserID: number;
  processing: boolean;
  userId: string;
  onView: (id: number) => void;
}

export const MessagesSection = ({
  conversationData,
  authorUserID,
  processing,
  onView,
  userId,
}: Props) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { currentMessageId } = useAppSelector((state) => state.message);
  const { socket } = useSocket();
  const { t } = useTranslation();

  const [userTyping, setUserTyping] = useState<{
    typingAt: string;
    userId: number;
  } | null>(null);

  const [showTyping, setShowTyping] = useState(false);
  const [isDeletedMessage, setDeletedMessage] = useState(false);

  useEffect(() => {
    if (isDeletedMessage) return;

    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversationData?.length, isDeletedMessage]);

  useEffect(() => {
    if (!socket) return;

    const handleTyping = (data: { typingAt: string; userId: number }) => {
      setUserTyping(data);
    };
    socket.on(EventTypeEnum.userTyping, handleTyping);
    return () => {
      socket.off(EventTypeEnum.userTyping, handleTyping);
    };
  }, [socket, setUserTyping]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (userTyping) {
        const typingTime = new Date(userTyping.typingAt).getTime();
        const currentTime = new Date().getTime();
        const elapsedSeconds = (currentTime - typingTime) / 1000;

        if (elapsedSeconds > 3) {
          setShowTyping(false);
        } else {
          setShowTyping(true);
        }
      } else {
        setShowTyping(false);
      }
    }, 300);

    return () => clearInterval(interval);
  }, [userTyping, setShowTyping]);

  useEffectOnce(() => {
    if (processing) {
      return;
    } else if (currentMessageId && conversationData) {
      const index = conversationData.findIndex(
        (message) => message.message.id === currentMessageId
      );
      if (index !== -1) {
        const currentMessageRef = document.getElementById(
          `conversation-card-${index}`
        );
        if (currentMessageRef) {
          currentMessageRef.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }
  });

  return (
    <ConversationWrapper>
      {!conversationData ? <DotsLoader /> : null}
      {conversationData?.map((c, index) => (
        <div key={c.message.id} id={`conversation-card-${index}`}>
          <ConversationCard
            data={c}
            key={index}
            authorUserID={authorUserID}
            currentMessage={c.message.id === currentMessageId}
            onView={onView}
            onRemoveMessage={setDeletedMessage}
          />
        </div>
      ))}
      <div ref={messagesEndRef} />
      {showTyping && Number(userId) === Number(userTyping?.userId) ? (
        <Stack
          display="flex"
          justifyContent="start"
          alignItems="center"
          flexDirection="row"
          gap={2}
        >
          <TypingAnimation />
          <Typography
            variant="body4"
            fontWeight={600}
            letterSpacing="0.5px"
            color="primary.main"
          >
            {t("typing")}
          </Typography>
        </Stack>
      ) : (
        <Stack height={14} />
      )}
    </ConversationWrapper>
  );
};
