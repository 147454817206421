import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_COLUMN_FULL, FLEX_ROW } from "ui-kit";

import { Card } from "@mui/material";

import project_construction_image from "../../../../../assets/backgrounds/project-construction.png";
import project_design_image from "../../../../../assets/backgrounds/project-design.png";
import project_structure from "../../../../../assets/backgrounds/project-structure.png";

export const Wrapper = styled(Card)`
  height: 350px;
  padding: ${({ theme }) => theme.spacing(4)};
`;

export const ContentWrapper = styled.div`
  height: calc(100% - 16px);
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const TitleWrapper = styled.div`
  ${FLEX_BETWEEN};
`;
export const WidgetContentWrapper = styled.div`
  ${FLEX_ROW};
  height: 100%;
  .designWidget_left_side {
    width: 50%;
    justify-content: end;
  }
  .designWidget_right_side {
    width: 50%;
    justify-content: start;
    background-image: url(${project_design_image});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .constructionWidget_left_side {
    width: 50%;
    justify-content: end;
  }
  .constructionWidget_right_side {
    width: 50%;
    justify-content: start;
    background-image: url(${project_construction_image});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .structure_right_side {
    width: 50%;
    justify-content: start;
    background-image: url(${project_structure});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

export const ArchiveContentWrapper = styled.div`
  ${FLEX_BETWEEN};
  height: 100%;
  gap: ${({ theme }) => theme.spacing(4)};
`;

export const StatsSectionWrapper = styled.div`
  ${FLEX_BETWEEN};
  background-color: ${({ theme }) => theme.palette.background.paper};
  width: 70%;
`;

export const OthersSection = styled.div`
  ${FLEX_BETWEEN};
  border-left: 4px solid ${({ theme }) => theme.palette.secondary.main};
  width: 30%;
  height: 130px;
  padding-left: 24px;
`;

export const ArchiveItemWrapper = styled.div`
  ${FLEX_COLUMN_FULL};
`;

export const ArchiveItem = styled.div`
  ${FLEX_COLUMN_FULL};
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  height: 76px;
  width: 76px;
`;
