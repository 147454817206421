import React from "react";

import { Typography } from "@mui/material";

import { WidgetContentWrapper, WidgetInfoWrapper } from "./styles";

export interface WidgetContentProps {
  desc?: string;
}

export const WidgetContent = ({ desc }: WidgetContentProps) => {
  return (
    <WidgetContentWrapper>
      {desc ? (
        <Typography variant="body2" color="text.secondary">
          {desc}
        </Typography>
      ) : (
        <WidgetInfoWrapper />
      )}
    </WidgetContentWrapper>
  );
};
