import { StructuralUnitTypesForCorrespondenceEnum } from "./correspondence";
import { IAttachLink } from "./messages";
import {
  IConstructionComplex,
  IOrganization,
  IPersonalData,
  IPosition,
  IStaffUnit,
} from "./organizations";
import { SingleApprovalOverdueStatusEnum } from "./standardRoutes";
import { StructuralUnitTypesEnum } from "./structuralUnits";

export enum TasksViewEnum {
  table = "table",
  calendar = "calendar",
  cards = "cards",
}

export enum TasksTabEnum {
  incoming = "incoming",
  outgoing = "outgoing",
}

export type PotentialExecutorAuthor = {
  employeeId: number;
  externalSubstitution: number | null;
  externalSubstitutionId?: number | null;
};

export enum TypeOfTheTaskEnum {
  resolutionForTheDocument = "resolution_for_the_document",
  taskByTheDocument = "task_by_the_document",
  directTask = "direct_task",
  selfAssignment = "self_assignment",
  requestToSupport = "request_to_support",
}

export enum TypeOfTheTaskAuthorEnum {
  system = "system",
  user = "user",
}

export enum StatusForAuthorEnum {
  draft = "draft",
  sentToExecutor = "sent_to_executor",
  done = "done",
  rejected = "rejected_by_executor",
  canceled = "canceled",
  removalFromControl = "removal_from_control",
  sentToSupport = "sent_to_support",
  inProgress = "in_progress",
  additionalInquiry = "additional_inquiry",
}

export enum StatusForExecutorEnum {
  newTask = "new_task",
  acceptedForExecution = "accepted_for_execution",
  delegated = "delegated",
  done = "done",
  doneInPerson = "done_in_person",
  rejected = "rejected",
  canceled = "canceled",
  resolutionIssued = "resolution_issued",
  executedWithoutResolution = "executed_without_resolution",
  executionInterrupted = "execution_interrupted",
  returnForRevision = "return_for_revision",
  executionCompleted = "execution_completed",
  newRequest = "new_request",
  acceptedForWork = "accepted_for_work",
}

export type TExecutionRequirement = {
  textOfTheTask: string;
  controlDate: string;
  controlTime: string;
  numberOfWorkingDays: number;
  numberOfCalendarDays: number;
  reportRequired: boolean;
  personalControl: boolean;
};

export type TWorkingDaysEditMode = {
  scheduledDateDisableState: boolean;
  workingDaysDisableState: boolean;
  calendarDaysDisableState: boolean;
};

export interface ITask {
  id: number;
  typeOfTheTask: TypeOfTheTaskEnum;
  statusForAuthor?: StatusForAuthorEnum;
  statusForExecutor?: StatusForExecutorEnum;
  subject: string;
  taskAuthorShortName: string;
  shortNameOfTheDocument?: string;
  standartTextForTheTask?: string;
  textOfTheTask: string;
  taskExecutorShortName: string;
  highPriority: boolean;
  createdAt: string | Date | null;
  scheduledDateOfExecution: string | Date | null;
  scheduledTimeOfExecution: string | null;
  actualDateOfExecution: string | Date | null;
  overdue: boolean;
  overdueStatus: string;
  internalRegistrationNumber: string;
}

export interface ISupportTask {
  id: number;
  taskAuthorShortName: string;
  statusForAuthor: StatusForAuthorEnum;
  textOfTheTask: string;
  createdAt: Date;
  highPriority: boolean;
  actualDateOfExecution: Date | null;
  internalRegistrationNumber: string;
  briefTextOrganizationName: string;
}

export interface ITaskForCalendar {
  delayed: boolean;
  id: number;
  typeOfTheTask: TypeOfTheTaskEnum;
  statusForExecutor: StatusForExecutorEnum;
  statusForAuthor: StatusForAuthorEnum;
  taskAuthorShortName: string;
  taskExecutorShortName: string;
  textOfTheTask: string;
  startDate: Date | string | null;
  endDate: Date | string | null;
}

export interface ITaskForCard {
  id: number;
  isFavorite: boolean;
  favoriteNumber: number;
  internalRegistrationNumber: string;
  typeOfTheTask: TypeOfTheTaskEnum;
  taskAuthorEmployeeId: number;
  taskAuthorExternalSubstitutionId: null;
  taskAuthorShortName: string;
  taskExecutorEmployeeId: number;
  taskExecutorExternalSubstitutionId: null;
  taskExecutorShortName: string;
  statusForExecutor: StatusForExecutorEnum;
  statusForAuthor: StatusForAuthorEnum;
  textOfTheTask: string;
  group: TaskCategoryEnum;
}

export type TRegistration = {
  taskNumber: number;
  taskDate: string;
  priority: boolean;
  constructionComplexAffiliation: Partial<IConstructionComplex>[];
  status: StatusForExecutorEnum;
  internalRegistrationNumber?: string;
};

export type TExecutorOfTheTask = {
  position: Partial<IPosition>;
  organization: Partial<IOrganization>;
  employee: { id: number; fullName: string };
  structuralUnit?: {
    id: number;
    nameOfStructuralUnit: string;
  };
};

export interface ITaskById {
  attachmentsCount?: number;
  authorOfTheTask: {
    position: Partial<IPosition>;
    organization: Partial<IOrganization>;
    employee: { id: number; fullName: string };
    structuralUnit?: {
      id: number;
      nameOfStructuralUnit: string;
      structuralUnitType?: StructuralUnitTypesForCorrespondenceEnum;
    };
  };
  dateOfViewingOfTheTask: Date | null;
  executorOfTheTask: TExecutorOfTheTask;
  registration: TRegistration;
  executionRequirements: TExecutionRequirement;
  typeOfTheTask: TypeOfTheTaskEnum;
  statusForExecutor: StatusForExecutorEnum;
  statusForAuthor: StatusForAuthorEnum;
  rootTaskId: number;
  subjectOfTask?: {
    id?: number;
    longNameOfTheDocument?: string;
    correspondenceId?: number;
  };
  hasOneSiblingTask?: boolean;
  areAllSiblingTasksDone?: boolean;
  hasValidChildTasks?: boolean;
  parentTaskId?: number | null;
}

export enum ExecutorType {
  Immediate_subordinates = "immediate_subordinates",
  All_subordinates = "all_subordinates",
}

export enum SubordinationType {
  immediate = "immediate", // непосредственные
  direct = "direct", // прямые
  notSubordinate = "not_subordinate", // не находятся в подчинении
}

export type PotentialExecutor = {
  organization: IOrganization;
  staffUnit: IStaffUnit;
  employeeId: number;
  externalSubstitutionId: number | null;
  structuralUnitId: number;
  personalData: IPersonalData;
  userId: number;
  id: number;
  subordinationType: SubordinationType;
  author: PotentialExecutorAuthor;
  structuralUnit: {
    name: string;
    id: number;
    type: StructuralUnitTypesEnum;
  };
  position?: string;
};

export type IExecutorOption = {
  title?: string;
  value: number;
  key?: number | undefined;
  uniqueId: number;
  id?: number | string;
  position?: string;
  organizationName?: string;
  authorExternalSubstitutionId?: number | null | undefined;
  externalSubstitutionId: number | null | undefined;
  authorEmployeeId: number;
  organizationId: number | undefined;
};

export type TaskExecutor = {
  employeeId?: number;
  uniqueId?: number;
  externalSubstitutionId?: number;
  authorEmployeeId?: number;
  authorExternalSubstitutionId?: number;
  organizationId: number;
  organizationName: string;
  position: string;
  textOfTheTask: string;
  highPriority: boolean;
  scheduledDateOfExecution: Date | string;
  scheduledTimeOfExecution: string;
  calendarDaysForExecution: number;
  workingDaysForExecution: number;
  reportRequired: boolean;
  personalControl: boolean;
  constructionComplexes?: number[];
  prevConstructionComplexes?: number[];
  affiliationConstructionComplex?: boolean;
  title?: string;
  id?: number;
};

export type CreateTaskDefaultValuesPerUser = {
  highPriority: boolean;
  textOfTheTask: string;
  personalControl: boolean;
  reportRequired: boolean;
  scheduledDateOfExecution?: Date | string;
  scheduledTimeOfExecution: string;
  workingDaysForExecution: number;
  calendarDaysForExecution?: number;
  constructionComplexes: number[];
  prevConstructionComplexes: number[];
  affiliationConstructionComplex: boolean;
  disabledConstructionComplexes: number[];
};

export interface CustomFile extends File {
  id?: string;
  path?: string;
  fileId?: string;
}

export type ICreateTask = {
  executorIds: TaskExecutor[];
  links?: IAttachLink[];
  files?: CustomFile[] | null;
  allSubordinates?: boolean;
  structuralUnitType?: ExecutorType;
  defaultValuesPerUser?: CreateTaskDefaultValuesPerUser;
};

export type ICreateFeedbackRequest = {
  textOfTheTask: string;
  highPriority: boolean;
  personalControl: boolean;
  reportRequired: boolean;
  links?: IAttachLink[];
  files?: CustomFile[] | null;
};

export type IEditTask = {
  affiliationConstructionComplex: boolean;
  highPriority: boolean;
  personalControl: boolean;
  reportRequired: boolean;
  textOfTheTask: string;
  constructionComplexAffiliation:
    | {
        id: number;
        briefTextConstructionComplexName: string;
      }[]
    | number[];
  scheduledDateOfExecution: string;
  scheduledTimeOfExecution: string;
  numberOfWorkingDays: number;
  calendarDaysForExecution: number;
  dateOfViewingOfTheTask: string | Date | null;
  executor: { title?: string; id?: number };
};

export type IEditTaskBody = {
  textOfTheTask?: string;
  scheduledDateOfExecution?: Date | string;
  calendarDaysForExecution?: number;
  workingDaysForExecution?: number;
  employeeId?: number;
};
export type IEditDraftTaskBody = {
  textOfTheTask?: string;
  scheduledDateOfExecution?: Date | string;
  calendarDaysForExecution?: number;
  workingDaysForExecution?: number;
  highPriority?: boolean;
  personalControl?: boolean;
  reportRequired?: boolean;
  constructionComplexes?:
    | {
        id: number;
        briefTextConstructionComplexName: string;
      }[]
    | number[];
};

export enum FileAttachedToTaskTypeOfAttachmentEnum {
  taskReport = "task_report",
  auxiliaryFile = "auxiliary_file",
  attachment = "attachment",
}

export enum TypeOfAttachmentEnum {
  taskReport = "task_report",
  other = "other",
  notClassified = "not_classified",
  taskSubject = "task_subject",
  attachmentToTask = "attachment_to_task",
  supportingDocument = "supporting_document",
}

export enum TypeOfAttachmentFileEnum {
  taskReport = "task_report",
  additionalFile = "auxiliary_file",
  attachmentToTheTask = "attachment",
}

export enum TypeOfAttachmentLinksEnum {
  taskReport = "task_report",
  additionalDocument = "additional_document",
  attachmentToTheTask = "attachment_to_task",
}

export type IAttachmentFile = {
  additionalInformation: string | null;
  createdAt: string;
  convertedPdfFileName: string | null;
  fileNameForSystem: string;
  fileFormat: string;
  fileName: string;
  id: number;
  fileId: number;
  uploaderId: number;
  typeOfAttachment: FileAttachedToTaskTypeOfAttachmentEnum;
  uploadedBy: string;
};

export type IAttachmentLink = {
  createdAt: string;
  attachedAt: string;
  createdBy: string;
  descriptionForAttachment: string | null;
  id: number;
  link: string;
  typeOfAttachment: TypeOfAttachmentEnum;
  contractId: null;
  messageId: number | null;
  correspondenceId: number | null;
  regularTaskId: number | null;
  supplementaryAgreementId: number | null;
  taskId: number | null;
  typeOfTheDocument: string;
  externalLinkUrl?: string;
};

export enum TaskCategoryEnum {
  requiresAction = "requires_action",
  inTheSpotlight = "in_the_spotlight",
  completed = "completed",
}

export interface IViewTask {
  id: number;
  view: boolean;
}

export type TExecutionTrackerNode = {
  id: number;
  taskId: number;
  childTaskId: number;
  generatedEventText: string;
  createdAt: string;
  titleForChildEvent: boolean;
  typeEventId: number;
  statusForExecutor: StatusForExecutorEnum;
  statusForAuthor: StatusForAuthorEnum;
  children: TExecutionTrackerNode[];
  parentId?: string | number;
  nameOfTypeEvent?: string;
};

export interface IAttachesTaskLinkBody {
  fileAppointment?: TypeOfAttachmentLinksEnum;
  descriptionForAttachment?: string;
  links: number[];
}

export enum TypeEventEnum {
  CreateTask = 1,
  DelegateTask = 2,
  EditTaskDueDate = 3,
  EditTaskAssignee = 4,
  NotifyAssigneeOfDueDateChange = 5,
  NotifyAssigneeOfAssigneeChange = 6,
  DeleteTask = 7,
  SendTaskToAssignee = 8,
  TaskAccepted = 9,
  MarkTaskAsPersonallyCompleted = 10,
  AutoMarkTaskAsRemovedFromControl = 11,
  AutoMarkTaskAsCompleted = 12,
  ApproveTaskCompletion = 13,
  ReturnTaskForRework = 14,
  NotifyAssigneeOfReturnForRework = 15,
  NotifyAssigneeOfOverdueTask = 16,
  NotifyAuthorOfOverdueTask = 17,
  NotifyAssigneeOfDueDateChangeRepeat = 18,
  RejectTask = 19,
  NotifyAuthorOfTaskRejection = 20,
  NotifyAuthorOfTaskCompletion = 21,
  IssueResolutionOnDocument = 22,
  AttachFile = 23,
  ViewTaskByAssignee = 24,
  AttachLinkToDocument = 25,
  CompleteDocumentExecution = 26,
  Title = 27,
  TaskExecutionInterrupted = 28,
  NotifyAuthorOfExecutionInterruption = 29,
  NotifyAssigneeOfNewTask = 30,
  EditTaskExecutorField = 31,
  EditTaskTextField = 32,
  EditTaskScheduledDateOfExecutionField = 33,
  NotifyAssigneeOfTaskChange = 34,
  CreateIncomingDocument = 35,
  CreateOutgoingDocument = 36,
  CreateServiceNote = 37,
  NotifyExecutorOfTaskDeletion = 38,
  SendIncomingDocumentForResolution = 39,
  CancellationOfAPreviouslyIssuedResolution = 40,
  ViewTaskByAuthor = 41,
  UpdateParentAssignmentStatus = 42,
  IncomingDocumentExecutionOfAllResolutions = 43,
  EditContractCard = 44,
  SendContractToContractDepartment = 45,
  CreateRequestToSBM = 46,
  NotifyAssigneeOfApproachingDueDate = 47,
  TaskOverdue = 48,
  EditAttachmentToTheTask = 49,
  DeleteAttachmentToTheTask = 50,
  ContractResponsibleAssignment = 51,
  ContractStatusChange = 52,
  ChangeContractResponsible = 53,
  ContractAssigningNumber = 54,
  ContractDeletion = 55,
  CorrespondenceApprovalRouteConfiguration = 56,
  SendTaskToArchiveByUser = 57,
  SendTaskToArchiveBySystem = 58,
  CancelTaskDoneStatus = 59,
  NotifyAuthorAndExecutorOfTaskCancellation = 60,
  NotifyRecipientOfResolutionTransfer = 61,
  RegisterRequest = 62,
  NotifyAuthorOfRequestRegistration = 63,
  NotifyExecutorOfHighPriorityRequest = 64,
  DeleteIncomingDocument = 75,
  DeleteOutgoingDocument = 76,
  DeleteServiceNote = 77,
  DeleteAttachedFileFromIncomingDocument = 79,
  DeleteAttachedFileFromOutgoingDocument = 80,
  DeleteAttachedFileFromServiceNote = 81,
  EditAttachedFileInIncomingDocument = 82,
  EditAttachedFileInOutgoingDocument = 83,
  EditAttachedFileInServiceNote = 84,
  CreateContract = 85,
  CreatePresignedContract = 86,
  LaunchOutgoingDocumentAgreement = 87,
  LaunchServiceNoteAgreement = 88,
  CompleteOutgoingDocumentAgreement = 89,
  CompleteServiceNoteAgreement = 90,
  LaunchContractAgreement = 94,
  LaunchSupplementaryAgreementApproval = 95,
  CompleteContractAgreement = 96,
  CompleteSupplementaryAgreementApproval = 97,
  ContractApprovalRouteConfiguration = 93,
}

export interface IRejectExecution {
  id: number;
  comment: string;
}

export interface IAcceptExecution {
  id: number;
  comment?: string;
}

export interface ISubordinateOption {
  title?: string;
  value?: number;
  key?: number;
  uniqueId?: number;
  id?: number;
  position?: string;
  externalSubstitutionId?: number | null;
  authorEmployeeId?: number;
  organizationId?: number;
  organizationName?: string;
  structuralUnitName?: string;
  structuralUnitType?: StructuralUnitTypesEnum;
}

export interface IExecutionTrackerScheme {
  authorOrganizationName: string;
  authorPersonalDataId: number;
  authorPosition: string;
  authorShortName: string;
  authorStructuralUnitName: string;
  executorOrganizationName: string;
  executorPersonalDataId: number;
  executorPosition: string;
  executorShortName: string;
  executorStructuralUnitName: string;
  highPriority: false;
  id: number;
  parentId: null;
  registrationNumber: string;
  rootTaskId: number;
  standartTextForTheTask: null;
  statusForAuthor: StatusForAuthorEnum;
  statusForExecutor: StatusForExecutorEnum;
  textOfTheTask: string;
  typeOfTheTask: string;
  typeOfTheTaskAuthor: string;
  overdueStatus: SingleApprovalOverdueStatusEnum;
  mainCard?: boolean;
  childCard?: boolean;
}

export interface IFavoriteTask {
  taskId: number;
}
