import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useDeleteMessageMutation } from "api";
import { Modal } from "ui-kit";

import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { Button } from "@sbm/ui-components";

export const DeleteMessagePopup = ({
  open,
  onClose,
  data: { data },
}: {
  open: boolean;
  onClose: () => void;
  data: {
    data: {
      messageId: number;
      isMessageOutgoing: boolean;
      onRemoveMessage: (removing: boolean) => void;
    };
  };
}) => {
  const { t } = useTranslation();
  const [deleteForAll, setDeleteForAll] = useState<boolean>(false);

  const onSuccess = () => {
    data.onRemoveMessage(true);
    onClose();
  };

  const onError = () => {
    onClose();
  };

  const { mutate: deleteMessage, isLoading: isRemoveMessage } =
    useDeleteMessageMutation(onSuccess, onError);

  const content = {
    title: t("delete_message_text"),
    body: (
      <Stack display="flex" justifyContent="center" alignItems="center">
        <FormControlLabel
          label={t("delete_for_all_text")}
          disabled={data.isMessageOutgoing}
          control={
            <Checkbox
              value={deleteForAll}
              onClick={() => setDeleteForAll(!deleteForAll)}
            />
          }
        />
      </Stack>
    ),
    secondaryButton: (
      <Button
        onClick={onClose}
        variant="outlined"
        size="large"
        color="secondary"
      >
        {t("cancel")}
      </Button>
    ),
    mainButton: (
      <Button
        variant={isRemoveMessage ? "outlined" : "contained"}
        color={"secondary"}
        size="large"
        loading={isRemoveMessage}
        onClick={() => {
          deleteMessage({ messageId: data.messageId, deleteForAll });
        }}
      >
        {t("delete")}
      </Button>
    ),
  };

  return <Modal isOpen={open} onClose={onClose} content={content} />;
};
