import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useViewMessageMutation } from "api";
import {
  setModal,
  setShowMessageDetails,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Icon } from "ui-kit";

import {
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { getLocaleDateFormat } from "@sbm/fe-utils";
import { ConversationResponse, ModalVariants } from "@types";

import { MessageOptionIcon, Wrapper } from "./styles";

interface Props {
  data: ConversationResponse;
  authorUserID: number;
  currentMessage: boolean;
  onView: (id: number) => void;
  onRemoveMessage: (removing: boolean) => void;
}

export const ConversationCard = ({
  data,
  authorUserID,
  currentMessage,
  onView,
  onRemoveMessage,
}: Props) => {
  const theme = useTheme();
  const { message } = data;
  const { userProfile } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { mutateAsync: viewMessage } = useViewMessageMutation();

  const isMessageOutgoing = useMemo(() => {
    const isOutgoingMessage = data.user.id === authorUserID;
    if (userProfile?.id === authorUserID) {
      return isOutgoingMessage;
    }
    return !isOutgoingMessage;
  }, [data.user.id, authorUserID, userProfile?.id]);

  const handleView = async (id: number, isMessageOutgoing: boolean) => {
    if (isMessageOutgoing) await viewMessage({ id, view: true });
    onView(id);
  };

  const parser = new DOMParser();
  const parsedHtmlDoc = parser.parseFromString(
    message.textOfTheMessage,
    "text/html"
  );
  const htmlDoc = parsedHtmlDoc.getElementsByTagName("body");
  const messageBody = htmlDoc?.[0].innerHTML.replaceAll("classname", "class");

  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent> | undefined
  ) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleRemoveMessage = () => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.deleteMessage,
        content: {
          data: {
            messageId: data.message.id,
            isMessageOutgoing,
            onRemoveMessage,
          },
        },
      })
    );

    setAnchorEl(null);
  };

  return (
    <Stack
      display="flex"
      position="relative"
      flexDirection={isMessageOutgoing ? "row" : "row-reverse"}
      className="message"
    >
      <Stack
        onClick={() => handleView(message.id, isMessageOutgoing)}
        display="flex"
        alignItems="center"
        flexDirection={isMessageOutgoing ? "row" : "row-reverse"}
      >
        <Wrapper
          $isOutgoing={isMessageOutgoing}
          $currentMessage={currentMessage}
          onClick={() => {
            dispatch(setShowMessageDetails(data.message.id));
          }}
        >
          <Typography
            variant={
              isMessageOutgoing && !currentMessage && !message?.messageViewed
                ? "body2"
                : "subtitle2"
            }
            color="primary.main"
            sx={{ whiteSpace: "pre-line" }}
            fontWeight={
              isMessageOutgoing && !currentMessage && !message?.messageViewed
                ? 700
                : 400
            }
            dangerouslySetInnerHTML={{ __html: messageBody }}
          />

          <div className="message_description">
            <div>
              {message.hasAttachedFile ? (
                <Icon
                  name="Paperclip"
                  size={16}
                  color={theme.palette.primary.main}
                />
              ) : (
                <div />
              )}
              {message.hasAttachedLink ? (
                <Icon
                  name="Link"
                  size={16}
                  color={theme.palette.primary.main}
                />
              ) : (
                <div />
              )}
            </div>

            <Typography variant="body4">
              {message.inSendingProcess ? (
                "Sending..."
              ) : isMessageOutgoing ? (
                getLocaleDateFormat(message.createdAt, "hh:mm", true)
              ) : (
                <Stack direction="row" alignItems="center" gap={2}>
                  <Typography variant="body4">
                    {getLocaleDateFormat(message.createdAt, "hh:mm", true)}
                  </Typography>
                  {message?.messageViewed ? (
                    <Icon
                      name="CheckCheck"
                      size={16}
                      strokeWidth={2}
                      color={theme.palette.secondary.main}
                    />
                  ) : (
                    <Icon
                      name="Check"
                      size={16}
                      strokeWidth={2}
                      color={theme.palette.secondary.main}
                    />
                  )}
                </Stack>
              )}
            </Typography>
          </div>
        </Wrapper>
      </Stack>
      <Stack
        ml={isMessageOutgoing ? -4 : 0}
        mr={isMessageOutgoing ? 0 : 2}
        pt={4}
        className="message_options"
        sx={{
          opacity: open ? 1 : 0,
          position: "relative",
          transition: "opacity 0.3s",
        }}
        aria-expanded={open ? "true" : "false"}
      >
        <div>
          <Stack
            display="flex"
            flexDirection={isMessageOutgoing ? "row-reverse" : "row"}
            gap={2}
          >
            <MessageOptionIcon $isOutgoing={isMessageOutgoing}>
              <Icon
                name="MoreVerticalIcon"
                onClick={handlePopoverOpen}
                size={14}
                color={theme.palette.primary.main}
              />
            </MessageOptionIcon>
            <MessageOptionIcon $isOutgoing={isMessageOutgoing}>
              <Icon
                name="Reply"
                disabled
                size={14}
                color={theme.palette.primary.main}
              />
            </MessageOptionIcon>
          </Stack>

          <Menu
            id="long-menu"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: isMessageOutgoing ? "left" : "right",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            disableAutoFocusItem
            PaperProps={{
              style: {
                minWidth: "10ch",
                borderRadius: "16px",
                zIndex: 9,
              },
            }}
            MenuListProps={{
              autoFocus: false,
            }}
          >
            <MenuItem key="edit" disabled>
              <ListItemIcon>
                <Icon
                  name="PencilIcon"
                  size={18}
                  color={theme.palette.primary.main}
                />
              </ListItemIcon>
              <Typography variant="body1" lineHeight="18px">
                {t("edit")}
              </Typography>
            </MenuItem>
            <MenuItem key="edit" disabled>
              <ListItemIcon>
                <Icon
                  name="ForwardIcon"
                  size={20}
                  color={theme.palette.primary.main}
                />
              </ListItemIcon>
              <Typography variant="body1" lineHeight="18px">
                {t("forward")}
              </Typography>
            </MenuItem>

            <MenuItem key="edit" onClick={handleRemoveMessage}>
              <ListItemIcon>
                <Icon
                  name="Trash2"
                  size={18}
                  color={theme.palette.error.main}
                />
              </ListItemIcon>
              <Typography variant="body1" lineHeight="18px" color="error.main">
                {t("delete_message")}
              </Typography>
            </MenuItem>
          </Menu>
        </div>
      </Stack>
    </Stack>
  );
};
