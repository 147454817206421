import React from "react";

import { TIconNames } from "@types";

import { WidgetContent } from "./components/content";
import { WidgetAction } from "./components/footer";
import { WidgetHeader } from "./components/header";
import { WidgetWrapper } from "./styles";

export interface WidgetProps {
  title: string;
  id?: number;
  description?: string;
  icon?: TIconNames | null;
  href?: string;
  switcherOn?: boolean;
}

export const Widget = ({
  title,
  icon,
  href,
  description,
  switcherOn,
  id,
}: WidgetProps) => {
  return (
    <WidgetWrapper>
      <WidgetHeader
        href={href}
        icon={icon}
        title={title}
        switcherOn={switcherOn}
        id={id}
      />
      <WidgetContent desc={description} />
      <WidgetAction href={href} />
    </WidgetWrapper>
  );
};
