import React, { useEffect } from "react";

import * as echarts from "echarts";

import { useTheme } from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";

export const ContractChart = (props: { uniqueId?: number | string }) => {
  const theme = useTheme();

  useEffect(() => {
    const chart = echarts.init(
      document.getElementById(`myChart${props.uniqueId}`)!
    );

    const options: echarts.EChartsOption = {
      tooltip: [
        {
          trigger: "item",
          formatter: "{b} : {c}",
          borderColor: `${theme.palette.primary.main}`,
          backgroundColor: `${theme.palette.primary.main}`,

          textStyle: {
            fontWeight: 700,
            color: `${theme.palette.primary.contrastText}`,
          },
        },
      ],

      legend: {
        orient: "vertical",
        left: 10,
        top: "center",
        itemGap: 16,
        selectorPosition: "start",
        textStyle: {
          fontWeight: 600,
          fontSize: "14px",
          color: `${theme.palette.text.secondary}`,
          opacity: 0.7,
        },
        data: [
          { name: "Черновик" },
          { name: "На Согласовании" },
          { name: "На Подписании" },
        ],
      },

      series: [
        {
          name: "Radius Mode",
          type: "pie",
          color: [
            `${hexToRgba(theme.palette.primary.main, 0.45)}`,
            `${theme.palette.secondary.main}`,
            `${theme.palette.primary.light}`,
          ],
          radius: [40, 80],
          center: ["80%", "50%"],
          roseType: "radius",
          itemStyle: {
            borderRadius: 2,
          },
          label: {
            show: false,
          },
          emphasis: {
            label: {
              show: false,
            },
          },
          data: [
            { value: 40, name: "Черновик" },
            { value: 33, name: "На Согласовании" },
            { value: 28, name: "На Подписании" },
          ],
        },
      ],
    };

    chart.setOption(options);

    return () => {
      chart.dispose();
    };
  }, [
    props.uniqueId,
    theme.palette.primary.contrastText,
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.text.secondary,
  ]);

  return (
    <div
      id={`myChart${props.uniqueId}`}
      style={{
        width: "auto",
        height: "100%",
        margin: "0px -16px",
      }}
    ></div>
  );
};
