import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useCreateEmployee, useGetEmployeeById } from "api";
import {
  axiosService,
  closeDrawerWithData,
  getEmployees,
  getPersonalData,
  getStaffUnitsByStructuralUnitId,
  getStructuralUnitsAutocomplete,
  setModal,
  TransitionPrompt,
  updateEmployee,
  useAppDispatch,
  useAppSelector,
} from "app";
import { Drawer } from "ui-kit";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete,
  AutocompleteChangeReason,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { getPersonalDataName } from "@sbm/fe-utils";
import {
  Button,
  FormAutocomplete,
  FormRadio,
  FormSelect,
  Input,
} from "@sbm/ui-components";
import { useQueryClient } from "@tanstack/react-query";
import {
  EnumEmployeeType,
  ICreateEmployee,
  IStructuralUnit,
  ModalVariants,
  TypeEmploymentRelationshipEnum,
} from "@types";

import { getStructuredPayload } from "./helpers";
import { ActionsSection, FormWrapper, TitleWrapper } from "./styles";

interface Props {
  data?: ICreateEmployee & {
    title?: string;
    employeeIdUpdate?: number;
    headOfStructuralUnit?: boolean;
    employeeName?: string;
    externalSubstitution?: {
      organization: { fullTextOrganizationName: string };
    };
  };
  isViewMode: boolean;
  isDrawerMode?: boolean;
  isEditModeDrawer?: boolean;
}

export const CreateStaffMemberContainer: React.FC<Props> = ({
  data,
  isViewMode,
  isDrawerMode,
  isEditModeDrawer,
}) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const employeeTypePrevRef = React.useRef(EnumEmployeeType.employee);

  const { drawerWithData } = useAppSelector((state) => state.global);
  const { modal } = useAppSelector((state) => state.modals);

  const [showPrompt, setShowPrompt] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [assistantStructuralUnitOptions, setAssistantStructuralUnitOptions] =
    React.useState<{ title: string; id: number; type: string }[]>([]);

  const [inputEmployeeValue, setInputEmployeeValue] = React.useState("");
  const [inputStructuralUnitValue, setInputStructuralUnitValue] =
    React.useState("");

  const [employeeOptions, setEmployeeOptions] = React.useState<
    { title: string; id: number; briefTextOrganizationName?: string }[]
  >([]);

  const { data: employeeData } = useGetEmployeeById(
    data?.externalSubstitution ? data?.employeeId : data?.employeeIdUpdate
  );
  const isHeadOfStructuralUnit = data?.headOfStructuralUnit;
  const organizationId =
    modal?.content.data?.organizationId ||
    drawerWithData?.content.data.organizationId ||
    "";

  const defaultPersonalData = employeeData?.externalSubstitutions?.length
    ? employeeData?.externalSubstitutions.find(
        (e) => e.staffUnit?.organizationId === Number(organizationId)
      )?.assistant?.personalData
    : employeeData?.assistant?.personalData;

  const defaultAssistantName = defaultPersonalData
    ? getPersonalDataName(defaultPersonalData)
    : "";
  const defaultAssistantPosition = employeeData?.externalSubstitutions?.length
    ? employeeData?.externalSubstitutions.find(
        (e) => e.staffUnit?.organizationId === Number(organizationId)
      )?.assistant?.namePosition
    : employeeData?.assistant?.namePosition;

  const defaultAssistant = employeeData?.externalSubstitutions?.length
    ? employeeData?.externalSubstitutions.find(
        (e) => e.staffUnit?.organizationId === Number(organizationId)
      )?.assistant
    : employeeData?.assistant;

  const [assistantPositions, setAssistantPositions] = React.useState<
    { value: string; option: string; longName?: string }[]
  >(
    defaultAssistantPosition
      ? [
          {
            value: String(defaultAssistant?.staffUnitId),
            option: String(defaultAssistant?.namePosition),
          },
        ]
      : []
  );

  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    option: string;
    longName?: string;
  }>({ value: "", option: "" });

  const { mutate: createEmployee, isLoading } = useCreateEmployee();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    clearErrors,
  } = useForm<ICreateEmployee>({
    defaultValues: {
      structuralUnitId: data?.structuralUnitId,
      positionId: data?.positionId,
      staffUnitId: data?.staffUnitId,
      employeeType: data?.employeeType || EnumEmployeeType.employee,
      employeeId: data?.employeeId,
      employeeIdUpdate: data?.employeeIdUpdate,
      additionalInformation: data?.externalSubstitution
        ? data?.externalSubstitution?.organization?.fullTextOrganizationName
        : data?.additionalInformation,
      validity: data?.validity || true,
      additionalOptions: !!defaultAssistant?.structuralUnit?.id,
      assignAssistant: !!defaultAssistant?.structuralUnit?.id,
      assistantStructuralUnitId: defaultAssistant?.structuralUnit?.id,
      assistantStaffUnitId: defaultAssistant?.staffUnitId,
      assistantForHeadOfStructure: {},
      typeEmploymentRelationship:
        employeeData?.typeEmploymentRelationship ||
        TypeEmploymentRelationshipEnum.typeA,
    },
  });

  const {
    positionId,
    employeeId,
    validity,
    staffUnitId,
    employeeType,
    additionalInformation,
    employeeIdUpdate,
    additionalOptions,
    assignAssistant,
    assistantStructuralUnitId,
    note,
    assistantStaffUnitId,
  } = useWatch({
    control,
  });

  React.useEffect(() => {
    (async () => {
      if (employeeData) {
        setValue("additionalOptions", !!defaultAssistant?.structuralUnit?.id);
        setValue("assignAssistant", !!defaultAssistant?.structuralUnit?.id);
        setValue("assistantStaffUnitId", String(defaultAssistant?.staffUnitId));
        setValue(
          "assistantStructuralUnitId",
          defaultAssistant?.structuralUnit?.id
        );
        setSelectedOption({
          value: String(defaultAssistant?.staffUnitId) || "",
          option: defaultAssistant?.namePosition || "",
        });
        if (defaultAssistant?.structuralUnit.id) {
          const items: {
            staffUnitId: number;
            longName: string;
            namePosition: string;
          }[] = await dispatch(
            getStaffUnitsByStructuralUnitId(
              Number(defaultAssistant?.structuralUnit?.id)
            )
          );

          if (items.length) {
            setAssistantPositions(
              items.map((item) => ({
                value: String(item.staffUnitId),
                option: item.namePosition || "",
                longName: item.longName,
                staffUnitId: item.staffUnitId,
              }))
            );
          }
        }
      }
    })();
  }, [
    employeeData,
    defaultAssistant?.structuralUnit?.id,
    defaultAssistant?.staffUnitId,
    defaultAssistant?.namePosition,
    defaultAssistantPosition,
    setValue,
    dispatch,
    t,
  ]);

  const structuralUnitOptions = React.useMemo(() => {
    if (!data) return [];

    return [{ value: data.structuralUnitId!, option: data.name! }];
  }, [data]);

  const positionOptions = React.useMemo(() => {
    if (!data) return [];

    return [{ value: data.positionId!, option: data.nameOfPosition! }];
  }, [data]);

  const assistantName = React.useMemo(() => {
    if (!assistantPositions.length) return t("vacancy");

    return (
      assistantPositions.find((item) => item.value === assistantStaffUnitId)
        ?.longName || t("vacancy")
    );
  }, [assistantPositions, assistantStaffUnitId, t]);

  const isRequiredFieldsFilled = isHeadOfStructuralUnit
    ? [
        staffUnitId,
        positionId,
        employeeId,
        ...(assignAssistant
          ? [!!assistantStaffUnitId, assistantStructuralUnitId]
          : []),
      ].every(Boolean)
    : [staffUnitId, positionId, employeeId].every(Boolean);

  const additionTypeOptions = [
    {
      option: t("adding.full.time.employee"),
      value: EnumEmployeeType.employee,
    },
    {
      option: t("adding.external.overlap"),
      value: EnumEmployeeType.substitution,
    },
  ];

  const employmentTypeOptions = React.useMemo(
    () => [
      {
        option: t("type_a"),
        value: TypeEmploymentRelationshipEnum.typeA,
      },
      {
        option: t("type_b"),
        value: TypeEmploymentRelationshipEnum.typeB,
      },
      ...(employeeType !== EnumEmployeeType.substitution
        ? [
            {
              option: t("type_c"),
              value: TypeEmploymentRelationshipEnum.typeC,
            },
          ]
        : []),
    ],
    [employeeType, t]
  );

  const ModalTitle = React.useMemo(() => {
    if (isViewMode && data && data.title) return data.title;

    if (employeeType === EnumEmployeeType.employee)
      return t("adding.staff.member");

    return t("adding.external.overlap");
  }, [isViewMode, data, employeeType, t]);

  const toggleEditMode = () => {
    setIsEditMode((prevState) => !prevState);
  };

  const handleValidityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      data?.employeeType === EnumEmployeeType.employee &&
      employeeData?.hasExternalSubstitutions
    ) {
      dispatch(
        setModal({
          open: true,
          variant: ModalVariants.invalidateEmployee,
          content: data,
        })
      );
    } else {
      register("validity").onChange(event);
    }
  };

  const handleEmployeeInputChange = (val: string, reason: string) => {
    if (val === inputEmployeeValue) return;

    setInputEmployeeValue(val);
    // Delete action
    if (reason === "clear") {
      clearErrors("employeeId");
      setValue("additionalInformation", "");
      return setValue("employeeId", undefined, { shouldDirty: true });
    }
    // Action is select from the list
    if (reason === "reset") {
      const option = employeeOptions.find((i) => i.title === val);
      if (!option) return;

      if (employeeType === EnumEmployeeType.substitution) {
        setValue("additionalInformation", option.briefTextOrganizationName);
      }
      return setValue("employeeId", option.id, { shouldDirty: true });
    }
  };

  const getEmployeeDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !employeeOptions.length) return;

      const employee = employeeOptions.find((i) => i.id === id);
      if (!employee) return;
      return employee.title;
    },
    [employeeOptions]
  );

  const getAssistantUnitDefaultValue = React.useCallback(
    (id?: number) => {
      const title = defaultAssistant?.structuralUnit?.nameOfStructuralUnit;
      if (!id && assistantStructuralUnitOptions.length) return;

      const unit = assistantStructuralUnitOptions.find((i) => i.id === id);
      if (unit) {
        return unit?.title;
      } else if (title) {
        return title;
      }
    },
    [
      assistantStructuralUnitOptions,
      defaultAssistant?.structuralUnit?.nameOfStructuralUnit,
    ]
  );

  const handleStructuralUnitInputChange = async (
    val: string,
    reason: string
  ) => {
    setInputStructuralUnitValue(val);

    // Delete action
    if (reason === "clear") {
      setAssistantPositions([]);
      setValue("assistantStaffUnitId", undefined, { shouldDirty: true });
      setSelectedOption({ value: "", option: "", longName: "" });
      return setValue("assistantStructuralUnitId", undefined, {
        shouldDirty: true,
      });
    }
    // Action is select from the list
    if (reason === "reset") {
      const option = assistantStructuralUnitOptions.find(
        (i) => i.title === val
      );

      if (!option) return;

      const items: {
        staffUnitId: number;
        longName: string;
        namePosition: string;
      }[] = await dispatch(getStaffUnitsByStructuralUnitId(Number(option.id)));

      if (items.length) {
        setAssistantPositions(
          items.map((item) => ({
            value: String(item.staffUnitId),
            option: item.namePosition || "",
            longName: item.longName,
            staffUnitId: item.staffUnitId,
          }))
        );
      }

      setSelectedOption({
        value: "",
        option: "",
      });
      setValue("assistantStaffUnitId", undefined, { shouldDirty: true });

      return setValue("assistantStructuralUnitId", option.id, {
        shouldDirty: true,
      });
    }
  };

  const fetchEmployees = React.useCallback(
    async (search: string) => {
      const isEmployee = employeeType === EnumEmployeeType.employee;

      const getDataCallback = isEmployee ? getPersonalData : getEmployees;

      // if selected full-time we should use getPersonalData api, if external  - getEmployees
      const { items } = await dispatch(getDataCallback(search));

      const options = items
        .map((i: any) => ({
          title: getPersonalDataName(isEmployee ? i : i.personalData),
          id: i.id,
          briefTextOrganizationName:
            i.staffUnit?.organization?.briefTextOrganizationName,
        }))
        .filter((i: { title: string | undefined }) => Boolean(i.title));

      // We have pagination limit 200 for getting employee list, in case the selected employee is not included adding it manually
      if (employeeId && isViewMode && isEmployee) {
        const exists = options.findIndex(
          (i: typeof items) => i.id === employeeId
        );

        if (exists === -1) {
          try {
            const resp = await axiosService({
              endpoint: `personal-data/${employeeId}`,
            });

            const optionToAdd = {
              title: getPersonalDataName(resp.data),
              id: resp.data.id,
            };

            options.push(optionToAdd);
          } catch (e) {
            console.log(e, "eee");
          }
        }
      }

      return options;
    },
    [dispatch, employeeType, employeeId, isViewMode]
  );

  const handleUpdateEmployee = async (form: Partial<ICreateEmployee>) => {
    if (!employeeId || !organizationId) return;

    await dispatch(
      updateEmployee(form, String(employeeIdUpdate), organizationId)
    );

    await queryClient.invalidateQueries(["get_staff_allocations"]);

    setLoading(false);
    dispatch(setModal(null));
    dispatch(closeDrawerWithData());
  };

  const handleClose = () => {
    if (Object.keys(dirtyFields).length > 0 || inputEmployeeValue) {
      setShowPrompt(true);
      return;
    }
    dispatch(closeDrawerWithData());
  };

  const handleConfirmTransition = () => {
    dispatch(closeDrawerWithData());
    handleClosePrompt();
  };

  const handleClosePrompt = () => {
    setShowPrompt(false);
  };

  const fetchStructuralUnits = React.useCallback(
    async (search: string) => {
      if (!organizationId) return;

      const { items } = await dispatch(
        getStructuralUnitsAutocomplete(search, organizationId as string)
      );

      return items
        .map((i: IStructuralUnit) => ({
          title: i.nameOfStructuralUnit,
          id: i.id,
        }))
        .filter((i: { title: string | undefined }) => Boolean(i.title));
    },
    [dispatch, organizationId]
  );

  const handleSelectChange = async (
    value: { value: string; option: string; longName?: string } | null,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === "clear" || !value) {
      setSelectedOption({ value: "", option: "", longName: "" });
      setValue("assistantStaffUnitId", undefined, { shouldDirty: true });
      if (assistantStructuralUnitId) {
        const items: {
          staffUnitId: number;
          longName: string;
          namePosition: string;
        }[] = await dispatch(
          getStaffUnitsByStructuralUnitId(assistantStructuralUnitId)
        );

        if (items.length) {
          setAssistantPositions(
            items.map((item) => ({
              value: String(item.staffUnitId),
              option: item.namePosition || "",
              longName: item.longName,
              staffUnitId: item.staffUnitId,
            }))
          );
        }
      }
      return;
    }
    setSelectedOption(value);
    setValue("assistantStaffUnitId", value.value, { shouldDirty: true });
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);

    if (!additionalOptions) {
      delete data["typeEmploymentRelationship"];
    }

    delete data["additionalOptions"];
    const dataToSend = getStructuredPayload(
      data,
      isViewMode,
      isHeadOfStructuralUnit
    );

    if (isEditMode || isEditModeDrawer) {
      const editedData = {
        additionalInformation: dataToSend.additionalInformation,
        validity: dataToSend.validity,
        employeeType: dataToSend.employeeType,
        typeEmploymentRelationship: dataToSend.typeEmploymentRelationship,
        ...(dataToSend?.assistantForHeadOfStructure && {
          assistantForHeadOfStructure: dataToSend.assistantForHeadOfStructure,
        }),
      };

      if (data.validity === false) {
        return dispatch(
          setModal({
            open: true,
            variant: ModalVariants.confirmDisableStaffMember,
            content: {
              confirmNavigation: () => handleUpdateEmployee(editedData),
              close: () => setLoading(false),
            },
          })
        );
      }
      await handleUpdateEmployee(editedData);
    } else {
      createEmployee(dataToSend, organizationId);
    }

    setLoading(false);
    dispatch(setModal(null));
    dispatch(closeDrawerWithData());
  });

  React.useEffect(() => {
    const type = data?.externalSubstitution
      ? employeeData?.externalSubstitutions?.find(
          (item) => item.id === data.employeeIdUpdate
        )?.typeEmploymentRelationship
      : employeeData?.typeEmploymentRelationship;

    if (type) {
      setValue("typeEmploymentRelationship", type);
    }
  }, [
    data?.externalSubstitution,
    data?.employeeIdUpdate,
    employeeData?.externalSubstitutions,
    employeeData?.typeEmploymentRelationship,
    setValue,
  ]);

  React.useEffect(() => {
    if (employeeType && !isViewMode) {
      setValue("additionalInformation", "");
      setValue("employeeId", undefined);
    }
  }, [employeeType, setValue, isViewMode]);

  React.useEffect(() => {
    if (employeeType && employeeType !== employeeTypePrevRef.current) {
      employeeTypePrevRef.current = employeeType;
    }
  }, [employeeType]);

  if (isDrawerMode) {
    return (
      <>
        {showPrompt && (
          <TransitionPrompt
            open={showPrompt}
            onClose={handleClosePrompt}
            onConfirm={handleConfirmTransition}
          />
        )}
        <Drawer
          anchor="right"
          open={Boolean(data)}
          onClose={handleClose}
          resizable={false}
          title={t(isEditModeDrawer ? "edit_employee" : "add_employee")}
          actions={
            <Stack display="flex" flexDirection="row" justifyContent="flex-end">
              <Stack flexDirection="row" gap={4}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={handleClose}
                >
                  {t("cancel")}
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  loading={loading || isLoading}
                  disabled={
                    !isRequiredFieldsFilled || !Object.keys(dirtyFields).length
                  }
                  onClick={onSubmit}
                >
                  {t("save")}
                </Button>
              </Stack>
            </Stack>
          }
        >
          <FormWrapper noValidate isDrawerMode>
            <Stack width="100%" flexDirection="column" gap={4}>
              <FormSelect
                readOnly
                label={t("structural.unit")}
                name="structuralUnitId"
                control={control}
                values={structuralUnitOptions}
              />
              <FormSelect
                readOnly
                label={t("position")}
                name="positionId"
                control={control}
                values={positionOptions}
              />
              <FormRadio
                disabled={isViewMode}
                name="employeeType"
                control={control}
                values={additionTypeOptions}
              />
              <FormAutocomplete
                required
                disabled={!employeeType}
                readOnly={isViewMode}
                inputValue={inputEmployeeValue}
                freeSolo={false}
                label={t("employee.fullname")}
                fetchData={fetchEmployees}
                defaultSelected={getEmployeeDefaultValue(employeeId)}
                onFetchDataSuccess={setEmployeeOptions}
                onInputChange={handleEmployeeInputChange}
                error={!!errors.employeeId}
              />
              <Input
                shrink
                value={additionalInformation}
                readOnly={
                  employeeType === EnumEmployeeType.substitution &&
                  isEditModeDrawer
                }
                maxLength={300}
                label={
                  employeeType === EnumEmployeeType.employee
                    ? t("additional.information")
                    : t("name.of.organization")
                }
                {...register("additionalInformation")}
              />
              {isEditModeDrawer && (
                <FormControlLabel
                  label={
                    employeeType === EnumEmployeeType.substitution
                      ? t("valid.substitution")
                      : t("valid.employee")
                  }
                  control={
                    <Switch
                      color="secondary"
                      defaultChecked={validity}
                      {...register("validity")}
                      onChange={handleValidityChange}
                    />
                  }
                />
              )}
              <FormControlLabel
                label={t("additional_options")}
                control={
                  <Switch
                    color="secondary"
                    checked={additionalOptions}
                    {...register("additionalOptions")}
                  />
                }
              />
              {additionalOptions && (
                <Stack gap={2}>
                  <Typography variant="body1" fontWeight="bold">
                    {t("employment_type")}
                  </Typography>
                  <FormRadio
                    name="typeEmploymentRelationship"
                    control={control}
                    values={employmentTypeOptions}
                  />
                </Stack>
              )}
              {isHeadOfStructuralUnit && additionalOptions && (
                <>
                  <FormControlLabel
                    label={t("assign_assistant")}
                    control={
                      <Switch
                        color="secondary"
                        defaultChecked={assignAssistant}
                        {...register("assignAssistant")}
                      />
                    }
                  />
                  {assignAssistant && (
                    <>
                      <FormAutocomplete
                        required
                        inputValue={inputStructuralUnitValue}
                        freeSolo={false}
                        label={t("structural.unit")}
                        fetchData={fetchStructuralUnits}
                        defaultSelected={getAssistantUnitDefaultValue(
                          assistantStructuralUnitId
                        )}
                        onFetchDataSuccess={setAssistantStructuralUnitOptions}
                        onInputChange={handleStructuralUnitInputChange}
                        error={!!errors.assistantStructuralUnitId}
                      />
                      <Autocomplete
                        options={assistantPositions}
                        value={selectedOption}
                        disabled={
                          !assistantStructuralUnitId &&
                          !defaultAssistant?.structuralUnit
                            ?.nameOfStructuralUnit
                        }
                        onChange={(_, newValue, reason) =>
                          handleSelectChange(newValue, reason)
                        }
                        getOptionLabel={(option) => option.option || ""}
                        sx={{ mb: 6 }}
                        renderOption={(props, option) => (
                          <li {...props} key={props.id}>
                            <Stack
                              flexDirection="column"
                              justifyContent="center"
                              gap={1}
                            >
                              <Typography>{option.option}</Typography>
                              <Typography color={theme.palette.text.secondary}>
                                {option.longName}
                              </Typography>
                            </Stack>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            variant="outlined"
                            color="secondary"
                            size="medium"
                            label={t("position")}
                          />
                        )}
                      />
                      <Input
                        disabled
                        shrink
                        value={assistantName}
                        readOnly={
                          employeeType === EnumEmployeeType.substitution &&
                          isEditModeDrawer
                        }
                        maxLength={3000}
                        label={t("employee.fullname")}
                      />
                      <Input
                        value={note}
                        defaultValue={defaultAssistant?.additionalInformation}
                        label={t("note")}
                        maxLength={3000}
                        {...register("note")}
                      />
                    </>
                  )}
                </>
              )}
            </Stack>
          </FormWrapper>
        </Drawer>
      </>
    );
  }

  return (
    <FormWrapper noValidate onSubmit={onSubmit}>
      <TitleWrapper>
        <Typography variant="h6" fontWeight={500}>
          {ModalTitle}
        </Typography>

        {isViewMode ? (
          <Button
            fullWidth={false}
            variant="contained"
            color="inherit"
            size="small"
            onClick={toggleEditMode}
            startIcon={isEditMode ? <ArrowBackIcon /> : <EditIcon />}
          >
            {isEditMode ? t("back") : t("edit")}
          </Button>
        ) : null}
      </TitleWrapper>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormSelect
            readOnly
            label={t("structural.unit")}
            name="structuralUnitId"
            control={control}
            values={structuralUnitOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <FormSelect
            readOnly
            label={t("position")}
            name="positionId"
            control={control}
            values={positionOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <FormRadio
            disabled={isViewMode}
            name="employeeType"
            control={control}
            values={additionTypeOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <FormAutocomplete
            required
            readOnly={isViewMode}
            disabled={!employeeType}
            inputValue={inputEmployeeValue}
            freeSolo={false}
            label={t("employee.fullname")}
            fetchData={fetchEmployees}
            defaultSelected={getEmployeeDefaultValue(employeeId)}
            onFetchDataSuccess={setEmployeeOptions}
            onInputChange={handleEmployeeInputChange}
            error={!!errors.employeeId}
          />
        </Grid>

        <Grid item xs={12} sm={7}>
          <Input
            shrink
            value={additionalInformation}
            readOnly={
              employeeType === EnumEmployeeType.substitution ||
              (isViewMode && !isEditMode)
            }
            label={
              employeeType === EnumEmployeeType.employee
                ? t("additional.information")
                : t("name.of.organization")
            }
            maxLength={300}
            {...register("additionalInformation")}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <FormControlLabel
            label={
              employeeType === EnumEmployeeType.substitution
                ? t("valid.substitution")
                : t("valid.employee")
            }
            labelPlacement="start"
            disabled={(isViewMode && !isEditMode) || !isViewMode}
            control={
              <Switch
                defaultChecked={validity}
                {...register("validity")}
                onChange={handleValidityChange}
              />
            }
          />
        </Grid>
      </Grid>

      <ActionsSection>
        {isViewMode && !isEditMode ? (
          <Button
            variant="contained"
            fullWidth
            onClick={() => dispatch(setModal(null))}
          >
            {t("close")}
          </Button>
        ) : (
          <Button
            variant="contained"
            fullWidth
            type="submit"
            loading={loading}
            disabled={
              loading ||
              !isRequiredFieldsFilled ||
              (isEditMode && !Object.keys(dirtyFields).length)
            }
          >
            {t("save")}
          </Button>
        )}
      </ActionsSection>
    </FormWrapper>
  );
};
