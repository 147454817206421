import React from "react";

import { Paths } from "app";
import { ContractChart } from "ui-kit";

import { Stack, Typography } from "@mui/material";
import { WidgetServiceEnum } from "@types";

export const getIconName = (serviceId: WidgetServiceEnum) => {
  switch (serviceId) {
    case WidgetServiceEnum.Contracts:
      return "FileSignature";
    case WidgetServiceEnum.ORD:
      return "FileText";
    case WidgetServiceEnum.Correspondence:
      return "Mail";
    case WidgetServiceEnum.Protocols:
      return "ScrollText";
    case WidgetServiceEnum.WorkPlans:
      return "ListOrdered";
    case WidgetServiceEnum.PowersOfAttorney:
      return "Stamp";
    case WidgetServiceEnum.Instructions:
      return "FileCog";
    case WidgetServiceEnum.Users:
      return "Users";
    case WidgetServiceEnum.Access:
      return "ShieldX";
    case WidgetServiceEnum.ApprovalRoutes:
      return "Route";
    case WidgetServiceEnum.Support:
      return "MessageCircle";
    case WidgetServiceEnum.Nomenclature:
      return "FileSignature";
    case WidgetServiceEnum.Applications:
      return "MousePointerClick";
    case WidgetServiceEnum.Limits:
      return "Timer";
    case WidgetServiceEnum.Payment:
      return "CircleDollarSign";
    case WidgetServiceEnum.Supply:
      return "Truck";
    case WidgetServiceEnum.Warehouses:
      return "Warehouse";
    default:
      return "";
  }
};

export const getMaintanenceMode = (serviceId: WidgetServiceEnum | null) => {
  switch (serviceId) {
    case WidgetServiceEnum.Contracts:
    case WidgetServiceEnum.Users:
    case WidgetServiceEnum.Access:
    case WidgetServiceEnum.ApprovalRoutes:
    case WidgetServiceEnum.Support:
    case WidgetServiceEnum.Nomenclature:
    case null:
      return false;
    default:
      return true;
  }
};

export const getChart = (serviceId: WidgetServiceEnum) => {
  switch (serviceId) {
    case WidgetServiceEnum.Contracts:
      return <ContractChart uniqueId={1} />;
    default:
      return null;
  }
};

export const getHref = (
  serviceId: WidgetServiceEnum,
  organizationId?: number | null
) => {
  if (organizationId) {
    return `${Paths.ORGANIZATIONS}/${organizationId}`;
  }

  switch (serviceId) {
    case WidgetServiceEnum.Contracts:
      return Paths.CONTRACTS;
    case WidgetServiceEnum.Organizations:
      return Paths.ORGANIZATIONS;
    case WidgetServiceEnum.Users:
      return Paths.USERS;
    case WidgetServiceEnum.Access:
      return Paths.ACCESS_MANAGEMENT;
    case WidgetServiceEnum.ApprovalRoutes:
      return Paths.APPROVAL_ROUTES;
    case WidgetServiceEnum.Support:
      return Paths.TECH_SUPPORT;
    case WidgetServiceEnum.Nomenclature:
      return Paths.NOMENCLATURE;
    default:
      return undefined;
  }
};

export const getDescription = (serviceId: WidgetServiceEnum) => {
  switch (serviceId) {
    // ToDo: add dynamic content
    case WidgetServiceEnum.Contracts:
      return (
        <Stack
          display="flex"
          justifyContent="space-around"
          gap={4}
          width="100%"
        >
          <Typography variant="body2" color="text.secondary">
            Общее количество действующих договоров
          </Typography>
          <Typography fontSize={20} fontWeight={700} color="text.secondary">
            {250}
          </Typography>
        </Stack>
      );
    default:
      return null;
  }
};
