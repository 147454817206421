import React from "react";

import {
  AccessManagementPage,
  ApprovalRoutesPage,
  DocumentsPage,
  HomePage,
  OrganizationByIdPage,
  OrganizationsListPage,
  OrganizationsPage,
  SettingsListPage,
  UsersManagement,
  ContractsPage,
  ContractByIdPage,
  CreateSAPage,
  EditSAPage,
  UserProfile,
  ViewTaskPage,
  CorrespondencePage,
  MaterialsPage,
  ViewIncomingDocumentPage,
  OrganizationsListByIdPage,
  TechSupportPage,
  NomenclaturePage,
  ApprovalPage,
  TaskPage,
  SubordinateTaskPage,
  MyDocumentsPage,
  ProjectsPage,
  ProjectsByIdPage,
  ProjectsArchivePage,
} from "../../pages";
import { Paths } from "./paths";

interface IRoute {
  path: string;
  Element: React.ElementType;
  withPermission?: boolean;
  blankPage?: boolean;
}

export const PRIVATE_ROUTES: IRoute[] = [
  {
    path: Paths.HOME,
    Element: HomePage,
  },
  {
    path: Paths.USERS,
    Element: UsersManagement,
    withPermission: true,
  },
  // {
  //   path: Paths.USERS_CREATE,
  //   Element: UserCreation,
  //   withPermission: true,
  // },
  {
    path: Paths.PROFILE,
    Element: () => <UserProfile />,
    withPermission: true,
  },
  {
    path: Paths.MY_PROFILE,
    Element: () => <UserProfile isSelf />,
  },
  {
    path: Paths.DOCUMENTS,
    Element: DocumentsPage,
  },
  {
    path: Paths.CONTRACTS,
    Element: ContractsPage,
  },
  {
    path: Paths.MY_APPROVALS,
    Element: ApprovalPage,
  },
  {
    path: Paths.MY_DOCUMENTS,
    Element: MyDocumentsPage,
  },
  {
    path: Paths.TASKS,
    Element: TaskPage,
  },
  {
    path: Paths.CONTRACT_BY_ID,
    Element: ContractByIdPage,
  },
  {
    path: Paths.CREATE_SA,
    Element: CreateSAPage,
  },
  {
    path: Paths.SA_BY_ID,
    Element: EditSAPage,
  },
  {
    path: Paths.ORGANIZATIONS,
    Element: OrganizationsPage,
  },
  {
    path: Paths.PROJECTS,
    Element: ProjectsPage,
  },
  {
    path: Paths.PROJECT_BY_ID,
    Element: ProjectsByIdPage,
  },
  {
    path: Paths.PROJECTS_ARCHIVE,
    Element: ProjectsArchivePage,
  },
  {
    path: Paths.ORGANIZATIONS_LIST,
    Element: OrganizationsListPage,
  },
  {
    path: Paths.ORGANIZATIONS_LIST_BY_ID,
    Element: OrganizationsListByIdPage,
  },
  {
    path: Paths.ORGANIZATION_BY_ID,
    Element: OrganizationByIdPage,
  },
  {
    path: Paths.SETTINGS,
    Element: SettingsListPage,
  },
  {
    path: Paths.APPROVAL_ROUTES,
    Element: ApprovalRoutesPage,
  },
  {
    path: Paths.ACCESS_MANAGEMENT,
    Element: AccessManagementPage,
  },
  {
    path: Paths.VIEW_TASK,
    Element: ViewTaskPage,
  },
  {
    path: Paths.CORRESPONDENCE,
    Element: CorrespondencePage,
  },
  {
    path: Paths.DOCUMENT_BY_ID,
    Element: ViewIncomingDocumentPage,
  },
  {
    path: Paths.TECH_SUPPORT_BY_ID,
    Element: ViewTaskPage,
  },
  {
    path: Paths.MATERIALS,
    Element: MaterialsPage,
  },
  {
    path: Paths.TECH_SUPPORT,
    Element: TechSupportPage,
  },
  {
    path: Paths.NOMENCLATURE,
    Element: NomenclaturePage,
  },
  {
    path: Paths.SUBORDINATE_TASKS,
    Element: SubordinateTaskPage,
    blankPage: true,
  },
];
