import React from "react";

import { TIconNames } from "@types";

import { WidgetAction } from "./components/footer";
import { WidgetHeader } from "./components/header";
import { WidgetMaintenanceMode } from "./components/maintenanceMode";
import {
  WidgetWrapper,
  WidgetDescriptionWrapper,
  WidgetChartWrapper,
} from "./styles";

export interface WidgetWithChartProps {
  title: string;
  description?: JSX.Element | null;
  customContent?: JSX.Element | null;
  icon?: TIconNames | null;
  href?: string;
  id?: number;
  chart?: JSX.Element | null;
  maintenanceMode?: boolean;
  disable?: boolean;
  translated?: boolean;
  switcherOn?: boolean;
}

export const WidgetWithChart = ({
  id,
  title,
  icon,
  href,
  maintenanceMode,
  chart,
  description,
  customContent,
  disable = false,
  translated = false,
  switcherOn = false,
}: WidgetWithChartProps) => {
  return (
    <WidgetWrapper>
      <WidgetHeader
        href={href}
        icon={icon}
        title={title}
        translated={translated}
        id={id}
        switcherOn={switcherOn}
        maintenanceMode={maintenanceMode}
      />

      {!maintenanceMode ? (
        <>
          {customContent ? (
            customContent
          ) : description ? (
            <WidgetDescriptionWrapper>{description}</WidgetDescriptionWrapper>
          ) : null}

          {chart && <WidgetChartWrapper>{chart}</WidgetChartWrapper>}

          <WidgetAction href={href} disable={disable} />
        </>
      ) : (
        <WidgetMaintenanceMode />
      )}
    </WidgetWrapper>
  );
};
