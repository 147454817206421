import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useGetWidgets } from "api";
import styled from "styled-components";
import { DotsLoader, Icon, Widget } from "ui-kit";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  accordionSummaryClasses,
  AccordionSummaryProps,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { TypeOfTheWidgetEnum, WidgetModuleEnum } from "@types";

import { ProjectMainWidget } from "../ProjectMainWidget";
import { UpperSection, Wrapper } from "./styles";

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<Icon name="ChevronRight" className="cursor-pointer" />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: "rotate(90deg)",
    },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

export const ProjectsModuleContainer = () => {
  const { palette } = useTheme();
  const { t } = useTranslation("project");

  const { data: widgets = [], isLoading } = useGetWidgets(
    WidgetModuleEnum.projects
  );

  const mainWidgetData = useMemo(() => {
    if (!widgets) return;

    return widgets.find(
      (w) => w.typeOfTheWidget === TypeOfTheWidgetEnum.mainWidgetForModule
    );
  }, [widgets]);

  const allProjectsData = useMemo(() => {
    if (!widgets) return [];

    return widgets.filter(
      (w) =>
        w.typeOfTheWidget === TypeOfTheWidgetEnum.project && !w.parentWidgetId
    );
  }, [widgets]);

  const groupOfWidgets = useMemo(() => {
    if (!widgets) return [];

    return widgets
      .filter((w) => w.typeOfTheWidget === TypeOfTheWidgetEnum.groupOfWidgets)
      .sort((a, b) => a.serialNumber! - b.serialNumber!);
  }, [widgets]);

  if (isLoading) return <DotsLoader />;

  return (
    <Wrapper>
      <UpperSection>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <ProjectMainWidget mainWidgetData={mainWidgetData} />
          </Grid>
        </Grid>
      </UpperSection>

      <Typography variant="h5" fontWeight={700}>
        {t("selected_projects")}
      </Typography>

      <Grid container mt={1} spacing={6}>
        {allProjectsData.map((prj, index) => (
          <Grid
            item
            key={prj.constructionComplexId || index}
            xs={12}
            md={6}
            xl={4}
          >
            <Widget
              title={prj.widgetName}
              href={`/projects/${prj.constructionComplexId}`}
              switcherOn={prj.switcherOn}
              id={prj.id}
            />
          </Grid>
        ))}
      </Grid>

      <Stack mt={6} gap={1}>
        {groupOfWidgets.length > 0
          ? groupOfWidgets.map((group) => {
              return (
                <Accordion
                  disableGutters
                  TransitionProps={{
                    timeout: { appear: 1, enter: 1, exit: 4 },
                  }}
                  key={group.id}
                  sx={{
                    background: palette.primary.main,
                    borderRadius: "16px !important",
                  }}
                >
                  <AccordionSummary
                    aria-controls={`${group.widgetName}-content`}
                    id={`${group.widgetName}-header`}
                    sx={{ px: 6 }}
                  >
                    <Typography
                      variant="h7"
                      fontWeight={700}
                      color={palette.primary.contrastText}
                      sx={{ py: 3 }}
                    >
                      {group.widgetName}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails sx={{ px: 6, pb: 6 }}>
                    <Grid container mt={1} spacing={6}>
                      {widgets
                        .filter((w, i) => w.parentWidgetId === group.id)
                        .map((prj) => (
                          <Grid
                            item
                            key={group.id + prj.id}
                            xs={12}
                            md={6}
                            xl={4}
                          >
                            <Widget
                              id={prj.id}
                              title={prj.widgetName}
                              href={`/projects/${prj.constructionComplexId}`}
                              switcherOn={prj.switcherOn}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })
          : null}
      </Stack>
    </Wrapper>
  );
};
