import React from "react";
import { useTranslation } from "react-i18next";

import { useSwitchWidgetMutation } from "api";
import { Icon } from "ui-kit";

import { Switch, Tooltip, Typography } from "@mui/material";
import { TIconNames } from "@types";

import { IconWrapper, WidgetHeaderWrapper } from "./styles";

export interface WidgetHeaderProps {
  title: string;
  icon?: TIconNames | null;
  href?: string;
  translated?: boolean;
  id?: number;
  switcherOn?: boolean;
  maintenanceMode?: boolean;
}

export const WidgetHeader = ({
  title,
  icon,
  href,
  translated = false,
  id,
  switcherOn,
  maintenanceMode,
}: WidgetHeaderProps) => {
  const { t } = useTranslation();

  const { mutate: updateSwitcher } = useSwitchWidgetMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!id) return;
    // timeout for sending one request
    setTimeout(() => {
      updateSwitcher({ id, validity: e.target.checked });
    }, 200);
  };

  return (
    <WidgetHeaderWrapper>
      <div className="widget_icon_title">
        {icon ? (
          <IconWrapper>
            <Icon name={icon} size={48} />
          </IconWrapper>
        ) : null}

        <Typography variant="h6" fontWeight={700} sx={{ textAlign: "initial" }}>
          {translated ? title : t(title)}
        </Typography>
      </div>
      {id ? (
        <Tooltip placement="top" title="Показать на главной странице">
          <Switch
            color="secondary"
            defaultChecked={switcherOn}
            onChange={handleChange}
          />
        </Tooltip>
      ) : null}
    </WidgetHeaderWrapper>
  );
};
