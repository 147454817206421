import React from "react";
import { Toaster } from "react-hot-toast";

import { Portal } from "@mui/material";
import { ModalVariants } from "@types";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { setModal } from "../../store";
import {
  TransitionPrompt,
  CreateOrganizationPopup,
  CreateStructuralUnitPopup,
  CreateStaffUnitPopup,
  ViewStructuralUnitPopup,
  CreateStaffMemberPopup,
  ConfirmStructuralUnitDisablePopup,
  CreateStandardRoutePopup,
  ViewStandardRoute,
  ConfirmApprovalRoutePopup,
  ContractApprovalActionsPopup,
  PermissionSetSavePopup,
  RemovePermissionSetPopup,
  EditRolePopup,
  RemoveRolePopup,
  EditUserRoleRolePopup,
  AttachLinkPopup,
  RejectExecutionPopup,
  RemoveExecutorPopup,
  AbortExecutionPopup,
  TaskSendForRevisionPopup,
  ConfirmExecutionPopup,
  FrameworkContractPopup,
  CompleteExecutionPopup,
  RemoveTaskPopup,
  RemoveFilePopup,
  RemoveOrganizationEmployeePopup,
  SendToResolutionPopup,
  AddFileToIncomingDocumentPopup,
  EditValidationEmployeePopup,
  RemoveContractPopup,
  AddAgreementRouteApproverPopUp,
  TaskSendToArchivePopup,
  ChangeApprovalRoutePopup,
  ExistingContractWarningPopup,
  AcceptExecutionPopup,
  ContractSetUpApprovalPopup,
  RemoveCorrespondencePopup,
  TaskEmployeePopup,
  DeleteDocumentPopup,
  DeleteMessagePopup,
} from "./components";

export const ModalsDecorator = () => {
  const dispatch = useAppDispatch();

  const { modal } = useAppSelector((state) => state.modals);

  const handleCloseModal = React.useCallback(() => {
    dispatch(setModal(null));

    if (modal?.content?.close) {
      modal.content.close();
    }
  }, [dispatch, modal?.content]);

  const handleConfirmTransition = React.useCallback(() => {
    handleCloseModal();

    if (modal?.content?.confirmNavigation) {
      modal.content.confirmNavigation();
    }
  }, [handleCloseModal, modal]);

  const ModalsByVariant = React.useMemo(() => {
    if (!modal) return null;

    switch (modal.variant) {
      case ModalVariants.discardChanges:
        return (
          <TransitionPrompt
            open={modal.open}
            onClose={handleCloseModal}
            onConfirm={handleConfirmTransition}
          />
        );
      case ModalVariants.createOrganization:
        return (
          <CreateOrganizationPopup
            open={modal.open}
            onClose={handleCloseModal}
          />
        );
      case ModalVariants.createStructuralUnit:
        return (
          <CreateStructuralUnitPopup
            open={modal.open}
            onClose={handleCloseModal}
          />
        );
      case ModalVariants.createStaffUnit:
        return (
          <CreateStaffUnitPopup open={modal.open} onClose={handleCloseModal} />
        );
      case ModalVariants.viewStructuralUnit:
        return (
          <ViewStructuralUnitPopup
            open={modal.open}
            onClose={handleCloseModal}
            structuralUnitId={modal.content.structuralUnitId}
            name={modal.content.name}
            organizationId={modal.content.organizationId}
          />
        );
      case ModalVariants.viewStaffUnit:
        return (
          <CreateStaffUnitPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content.data}
          />
        );
      case ModalVariants.createStaffMember:
        return (
          <CreateStaffMemberPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content.data}
          />
        );
      case ModalVariants.viewStaffMember:
        return (
          <CreateStaffMemberPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content.data}
            isViewMode={true}
          />
        );
      case ModalVariants.confirmDisableStructuralUnit:
      case ModalVariants.confirmDisableStaffMember:
      case ModalVariants.confirmDisableStaffUnit:
        return (
          <ConfirmStructuralUnitDisablePopup
            open={modal.open}
            onClose={handleCloseModal}
            onConfirm={handleConfirmTransition}
          />
        );
      case ModalVariants.confirmApprovalRoute:
        return (
          <ConfirmApprovalRoutePopup
            open={modal.open}
            onClose={handleCloseModal}
            onConfirm={handleConfirmTransition}
          />
        );
      case ModalVariants.createStandardRoute:
        return (
          <CreateStandardRoutePopup
            open={modal.open}
            onClose={handleCloseModal}
          />
        );
      case ModalVariants.viewStandardRoute:
        return (
          <ViewStandardRoute
            open={modal.open}
            routeId={modal.content?.routeId}
          />
        );
      case ModalVariants.contractApprovalActions:
        return (
          <ContractApprovalActionsPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content.data}
          />
        );
      case ModalVariants.permissionsSet:
        return (
          <PermissionSetSavePopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.removePermissionsSet:
        return (
          <RemovePermissionSetPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.editRole:
        return (
          <EditRolePopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.removeRole:
        return (
          <RemoveRolePopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.removeUserRole:
        return (
          <RemoveRolePopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.editUserRole:
        return (
          <EditUserRoleRolePopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.attachLink:
        return (
          <AttachLinkPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.removeExecutor:
        return (
          <RemoveExecutorPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.rejectExecution:
        return (
          <RejectExecutionPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.sendForRevision:
        return (
          <TaskSendForRevisionPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.completeExecution:
        return (
          <CompleteExecutionPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.abortExecution:
        return (
          <AbortExecutionPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.confirmExecution:
        return (
          <ConfirmExecutionPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.acceptExecution:
        return (
          <AcceptExecutionPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.frameworkContract:
        return (
          <FrameworkContractPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.removeTask:
        return (
          <RemoveTaskPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.removeContract:
        return (
          <RemoveContractPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.removeFile:
      case ModalVariants.removeCorrespondenceFile:
        return (
          <RemoveFilePopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.sendToArchive:
        return (
          <TaskSendToArchivePopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content?.data || {}}
          />
        );
      case ModalVariants.removeOrganizationEmployee:
        return (
          <RemoveOrganizationEmployeePopup
            open={modal.open}
            onClose={handleCloseModal}
            onRemove={modal.content.onRemove}
          />
        );
      case ModalVariants.sendToResolution:
        return (
          <SendToResolutionPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.addFileToIncomingDocument:
        return (
          <AddFileToIncomingDocumentPopup
            open={modal.open}
            onClose={handleCloseModal}
            onAddFile={modal.content.onAddFile}
            isServiceNote={modal.content.isServiceNote}
          />
        );
      case ModalVariants.invalidateEmployee:
        return (
          <EditValidationEmployeePopup
            open={modal.open}
            onClose={handleCloseModal}
          />
        );
      case ModalVariants.contractSetUpApproval:
        return (
          <ContractSetUpApprovalPopup
            open={modal.open}
            onClose={handleCloseModal}
          />
        );
      case ModalVariants.addAgreementRouteApprover:
        return (
          <AddAgreementRouteApproverPopUp
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.changeApprovalRoute:
        return (
          <ChangeApprovalRoutePopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.existingContractWarning:
        return (
          <ExistingContractWarningPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.removeCorrespondence:
        return (
          <RemoveCorrespondencePopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.deleteDocument:
        return (
          <DeleteDocumentPopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.deleteMessage:
        return (
          <DeleteMessagePopup
            open={modal.open}
            onClose={handleCloseModal}
            data={modal.content}
          />
        );
      case ModalVariants.taskEmployee:
        return (
          <TaskEmployeePopup open={modal.open} onClose={handleCloseModal} />
        );
      default:
        return null;
    }
  }, [handleCloseModal, handleConfirmTransition, modal]);

  return (
    <React.Fragment>
      <Portal>
        <Toaster containerStyle={{ zIndex: 99999 }} />
      </Portal>

      {ModalsByVariant}
    </React.Fragment>
  );
};

export * from "./components";
