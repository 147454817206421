export const Paths = {
  HOME: "/",
  USERS: "/settings/users",
  PROFILE: "/users/:id",
  MY_PROFILE: "/my-profile",
  USERS_CREATE: "/users/create",
  DOCUMENTS: "/documents",
  MY_APPROVALS: "/my-approvals",
  MY_DOCUMENTS: "/my-documents",
  CONTRACTS: "/documents/contracts",
  CONTRACT_BY_ID: "/documents/contracts/:id",
  CREATE_CONTRACT: "/documents/contracts/create",
  CREATE_SA: "/documents/contracts/:id/sa",
  SA_BY_ID: "/documents/contracts/:id/sa/:saID",
  HELP: "/help",
  ORGANIZATIONS: "/organizations",
  ORGANIZATIONS_LIST: "/organizations/list",
  ORGANIZATIONS_LIST_BY_ID: "/organizations/list/:id",
  CREATE_ORGANIZATION: "/organizations/create",
  ORGANIZATION_BY_ID: "/organizations/:id",
  SETTINGS: "/settings",
  APPROVAL_ROUTES: "/settings/approval-routes",
  ACCESS_MANAGEMENT: "/settings/access-management",
  VIEW_TASK: "/tasks/:id",
  CORRESPONDENCE: "/documents/correspondence",
  DOCUMENT_BY_ID: "/documents/correspondence/:id",
  MATERIALS: "/materials",
  REQUEST_TASK: "/requestTask/:id",
  TECH_SUPPORT: "/settings/tech-support",
  TECH_SUPPORT_BY_ID: "/settings/tech-support/:id",
  NOMENCLATURE: "/materials/nomenclature",
  TASKS: "/tasks",
  SUBORDINATE_TASKS: "/subordinate/tasks/:id",
  PROJECTS: "/projects",
  PROJECT_BY_ID: "/projects/:id",
  PROJECTS_ARCHIVE: "/projects/:id/archive",
  PROJECTS_STRUCTURE: "/projects/:id/structure",
};

export type TPathName = keyof typeof Paths;
