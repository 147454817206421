import React from "react";
import { useTranslation } from "react-i18next";

import { useSwitchWidgetMutation } from "api";
import { Icon, WidgetProps } from "ui-kit";

import { Switch, Tooltip, Typography } from "@mui/material";

import { IconWrapper, WidgetHeaderWrapper } from "./styles";

export const WidgetHeader = ({
  title,
  icon,
  href,
  switcherOn,
  id,
}: Partial<WidgetProps>) => {
  const { t } = useTranslation();

  const { mutate: updateSwitcher } = useSwitchWidgetMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!id) return;

    // timeout for sending one request
    setTimeout(() => {
      updateSwitcher({ id, validity: e.target.checked });
    }, 200);
  };

  return (
    <WidgetHeaderWrapper>
      <div className="widget_icon_title">
        {icon ? (
          <IconWrapper>
            <Icon name={icon} size={48} />
          </IconWrapper>
        ) : null}

        <Typography variant="h6" fontWeight={700} sx={{ textAlign: "initial" }}>
          {t(title || "")}
        </Typography>
      </div>
      {id ? (
        <Tooltip placement="top" title={"Показать на главной странице"}>
          <Switch
            color="secondary"
            defaultChecked={switcherOn}
            onChange={handleChange}
          />
        </Tooltip>
      ) : null}
    </WidgetHeaderWrapper>
  );
};
