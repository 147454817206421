import { format } from "date-fns";

import {
  ICreateFeedbackRequest,
  ICreateTask,
  IEditTask,
  IExecutorOption,
  StatusForAuthorEnum,
  StatusForExecutorEnum,
  TExecutionRequirement,
  TypeOfTheTaskEnum,
} from "@types";

export const createRequestBody = (
  body: ICreateTask,
  parentTaskId?: string,
  requirements?: TExecutionRequirement | undefined
) => {
  const formData = new FormData();

  if (body.files?.length) {
    const oldFiles = body.files.filter((f) => f.id);
    const newFiles = body.files.filter((f) => f.path);

    if (newFiles.length) {
      newFiles.forEach((file) => {
        formData.append("files", file);
      });
    }

    if (oldFiles.length) {
      oldFiles.forEach((file) => {
        if (file.id) {
          formData.append("fileIds[]", file.fileId!);
        }
      });
    }
  }

  if (body.links?.length) {
    const oldLinks = body.links.filter((link) => link.link);
    const newLinks = body.links.filter(
      (link) => link.longNameOfTheDocument || link.documentName
    );

    if (oldLinks.length) {
      oldLinks.forEach((link) => {
        formData.append("linkIds[]", link.id.toString());
      });
    }

    if (newLinks.length) {
      newLinks.forEach((link, index) => {
        formData.append(`links[${index}]`, link.id.toString());
      });
    }
  }

  if (body.executorIds.length > 0) {
    body.executorIds.map((item, index) => {
      const setScheduleDate = (date: Date | string, time: string) => {
        const newDate = new Date(date);
        const formattedDate = format(newDate, "yyyy-MM-dd");
        if (!time) {
          return String(formattedDate) + `T${requirements?.controlTime}.000Z`;
        }

        return String(formattedDate) + `T${time}.000Z`;
      };

      const executor: Record<
        string,
        string | number | undefined | boolean | Date | number[]
      > = {
        employeeId: item.uniqueId,
        externalSubstitutionId: Number(item.externalSubstitutionId),
        authorEmployeeId: item.authorEmployeeId,
        organizationId: item.organizationId,
        textOfTheTask: item.textOfTheTask,
        highPriority: item.highPriority,
        scheduledDateOfExecution: setScheduleDate(
          item.scheduledDateOfExecution,
          item.scheduledTimeOfExecution
        ),
        calendarDaysForExecution: item.calendarDaysForExecution,
        workingDaysForExecution: item.workingDaysForExecution,
        reportRequired: item.reportRequired,
        personalControl: item.personalControl,
        constructionComplexes: item.constructionComplexes,
      };

      if (item.externalSubstitutionId) {
        delete executor["employeeId"];
      } else {
        delete executor["externalSubstitutionId"];
      }

      Object.keys(executor).forEach((key) => {
        const value = executor[key];
        if (value !== undefined) {
          if (Array.isArray(value)) {
            value.forEach((v, i) =>
              formData.append(`executors[${index}][${key}][${i}]`, v.toString())
            );
          } else {
            formData.append(`executors[${index}][${key}]`, value.toString());
          }
        }
      });
    });
  }

  if (parentTaskId) {
    formData.append("parentTaskId", parentTaskId);
  }
  // formData.append("typeOfTheTask", TypeOfTheTaskEnum.directTask);

  return formData;
};

export const createRequestBodyInSBM = (body: ICreateFeedbackRequest) => {
  const formData = new FormData();

  if (body.files?.length) {
    const oldFiles = body.files.filter((f) => f.id);
    const newFiles = body.files.filter((f) => f.path);

    if (newFiles.length) {
      newFiles.forEach((file) => {
        formData.append("files", file);
      });
    }

    if (oldFiles.length) {
      oldFiles.forEach((file) => {
        if (file.id) {
          formData.append("fileIds[]", file.fileId!);
        }
      });
    }
  }

  if (body.links?.length) {
    const oldLinks = body.links.filter((link) => link.link);
    const newLinks = body.links.filter((link) => link.longNameOfTheDocument);

    if (oldLinks.length) {
      oldLinks.forEach((link) => {
        formData.append("linkIds[]", link.id.toString());
      });
    }

    if (newLinks.length) {
      newLinks.forEach((link, index) => {
        formData.append(`links[${index}]`, link.id.toString());
      });
    }
  }

  formData.append("textOfTheTask", String(body.textOfTheTask));
  formData.append("highPriority", String(body.highPriority));
  formData.append("reportRequired", "false");
  formData.append("personalControl", String(body.personalControl));

  return formData;
};

export const haveSameOrganizationName = (array: IExecutorOption[]) => {
  if (array.length === 0) {
    return false;
  }

  const firstOrganizationName = array[0].organizationName;

  for (let i = 1; i < array.length; i++) {
    if (array[i].organizationName !== firstOrganizationName) {
      return false;
    }
  }

  return true;
};

export const getFieldsDisableState = (
  taskType: TypeOfTheTaskEnum,
  authorStatus: StatusForAuthorEnum,
  executorStatus: StatusForExecutorEnum,
  dateOfViewingOfTheTask: Date | null
) => {
  const initialDisableState = {
    workingDaysEditMode: {
      scheduledDateDisableState: true,
      workingDaysDisableState: true,
      calendarDaysDisableState: true,
    },
    executorDisableState: true,
    reportRequiredDisableState: true,
    personalControlDisableState: true,
    textOfTheTaskDisableState: true,
    highPrioritySwitchDisableState: true,
    affiliationConstructionComplexDisableState: true,
    constructionComplexAffiliationDisableState: true,
    deleteBtnDisableState: true,
  };

  const updatedDisableState = { ...initialDisableState };

  switch (taskType) {
    case TypeOfTheTaskEnum.directTask:
      switch (authorStatus) {
        case StatusForAuthorEnum.sentToExecutor:
          if (!dateOfViewingOfTheTask) {
            updatedDisableState.deleteBtnDisableState = false;
            updatedDisableState.reportRequiredDisableState = false;
            updatedDisableState.personalControlDisableState = false;
            updatedDisableState.textOfTheTaskDisableState = false;
            updatedDisableState.textOfTheTaskDisableState = false;
            updatedDisableState.highPrioritySwitchDisableState = false;
            updatedDisableState.workingDaysEditMode.scheduledDateDisableState =
              false;
            updatedDisableState.workingDaysEditMode.calendarDaysDisableState =
              false;
            updatedDisableState.workingDaysEditMode.workingDaysDisableState =
              false;
            updatedDisableState.affiliationConstructionComplexDisableState =
              false;
            updatedDisableState.constructionComplexAffiliationDisableState =
              false;
          } else {
            updatedDisableState.workingDaysEditMode.scheduledDateDisableState =
              false;
          }
          break;
        case StatusForAuthorEnum.draft:
          updatedDisableState.deleteBtnDisableState = false;
          updatedDisableState.reportRequiredDisableState = false;
          updatedDisableState.personalControlDisableState = false;
          updatedDisableState.textOfTheTaskDisableState = false;
          updatedDisableState.textOfTheTaskDisableState = false;
          updatedDisableState.highPrioritySwitchDisableState = false;
          updatedDisableState.workingDaysEditMode.scheduledDateDisableState =
            false;
          updatedDisableState.workingDaysEditMode.calendarDaysDisableState =
            false;
          updatedDisableState.workingDaysEditMode.workingDaysDisableState =
            false;
          updatedDisableState.affiliationConstructionComplexDisableState =
            false;
          updatedDisableState.constructionComplexAffiliationDisableState =
            false;
          break;
        case StatusForAuthorEnum.rejected:
          updatedDisableState.workingDaysEditMode.scheduledDateDisableState =
            false;
          updatedDisableState.executorDisableState = false;
          updatedDisableState.textOfTheTaskDisableState = false;
          updatedDisableState.deleteBtnDisableState = false;
          break;
      }
      break;
    case TypeOfTheTaskEnum.taskByTheDocument:
      switch (authorStatus) {
        case StatusForAuthorEnum.sentToExecutor:
          if (!dateOfViewingOfTheTask) {
            updatedDisableState.deleteBtnDisableState = false;
          }
          updatedDisableState.workingDaysEditMode.scheduledDateDisableState =
            false;
          break;
        case StatusForAuthorEnum.rejected:
          updatedDisableState.workingDaysEditMode.scheduledDateDisableState =
            false;
          updatedDisableState.executorDisableState = false;
          updatedDisableState.textOfTheTaskDisableState = false;
          updatedDisableState.deleteBtnDisableState = false;
          break;
      }
      break;
    case TypeOfTheTaskEnum.selfAssignment:
      switch (executorStatus) {
        case StatusForExecutorEnum.newTask:
        case StatusForExecutorEnum.acceptedForExecution:
          updatedDisableState.workingDaysEditMode.scheduledDateDisableState =
            false;
          updatedDisableState.textOfTheTaskDisableState = false;
          updatedDisableState.deleteBtnDisableState = false;
          break;
      }
      break;
    default:
      break;
  }

  return updatedDisableState;
};

export const createEditDraftTaskRequestBody = (data: IEditTask) => {
  return {
    textOfTheTask: data.textOfTheTask,
    highPriority: data.highPriority,
    scheduledDateOfExecution:
      reformatDate(data.scheduledDateOfExecution) +
      `T${data.scheduledTimeOfExecution}.000Z`,
    calendarDaysForExecution: data.calendarDaysForExecution,
    workingDaysForExecution: data.numberOfWorkingDays,
    reportRequired: data.reportRequired,
    personalControl: data.personalControl,
    constructionComplexes: data.constructionComplexAffiliation.map((i) =>
      typeof i === "object" ? i.id : i
    ),
  };
};

export const reformatDate = (date: string) => {
  let parseDate: string[] = [];
  if (date.includes(".")) {
    parseDate = date.split(".");
  }
  if (date.includes("_")) {
    parseDate = date.split("_");
  }

  if (parseDate.length) {
    const newDate = `${parseDate[2]}-${parseDate[1]}-${parseDate[0]}`;
    return newDate;
  }

  return date;
};

export const createEditTaskRequestBody = (
  data: IEditTask,
  taskType: TypeOfTheTaskEnum,
  authorStatus: StatusForAuthorEnum,
  executorStatus: StatusForExecutorEnum
) => {
  switch (taskType) {
    case TypeOfTheTaskEnum.directTask:
      switch (authorStatus) {
        case StatusForAuthorEnum.sentToExecutor:
          return {
            scheduledDateOfExecution:
              reformatDate(data.scheduledDateOfExecution) +
              `T${data.scheduledTimeOfExecution}.000Z`,
            calendarDaysForExecution: data.calendarDaysForExecution,
            workingDaysForExecution: data.numberOfWorkingDays,
          };

        case StatusForAuthorEnum.rejected:
          return {
            scheduledDateOfExecution:
              reformatDate(data.scheduledDateOfExecution) +
              `T${data.scheduledTimeOfExecution}.000Z`,
            calendarDaysForExecution: data.calendarDaysForExecution,
            workingDaysForExecution: data.numberOfWorkingDays,
            employeeId: data.executor.id,
            textOfTheTask: data.textOfTheTask,
          };
      }
      break;
    case TypeOfTheTaskEnum.taskByTheDocument:
      switch (authorStatus) {
        case StatusForAuthorEnum.sentToExecutor:
          return {
            scheduledDateOfExecution:
              reformatDate(data.scheduledDateOfExecution) +
              `T${data.scheduledTimeOfExecution}.000Z`,
            calendarDaysForExecution: data.calendarDaysForExecution,
            workingDaysForExecution: data.numberOfWorkingDays,
          };
        case StatusForAuthorEnum.rejected:
          return {
            scheduledDateOfExecution: data.scheduledDateOfExecution,
            calendarDaysForExecution: data.calendarDaysForExecution,
            workingDaysForExecution: data.numberOfWorkingDays,
            employeeId: data.executor.id,
            textOfTheTask: data.textOfTheTask,
          };
      }
      break;
    case TypeOfTheTaskEnum.selfAssignment:
      switch (executorStatus) {
        case StatusForExecutorEnum.newTask:
        case StatusForExecutorEnum.acceptedForExecution:
          return {
            scheduledDateOfExecution:
              reformatDate(data.scheduledDateOfExecution) +
              `T${data.scheduledTimeOfExecution}.000Z`,
            calendarDaysForExecution: data.calendarDaysForExecution,
            workingDaysForExecution: data.numberOfWorkingDays,
            textOfTheTask: data.textOfTheTask,
          };
      }
  }
  return {};
};
