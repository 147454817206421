import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { setToggleBreadCrumb, useAppDispatch, useAppSelector } from "app";

import { Fade, Typography, useTheme } from "@mui/material";

import { Icon } from "../Icon";
import {
  BreadcrumbPathsWrapper,
  BreadcrumbsWrapper,
  BreadcrumbTrigger,
} from "./styles";

export const Breadcrumbs = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { breadCrumbOpen } = useAppSelector((state) => state.global);
  const { organizationName } = useAppSelector((state) => state.organizations);

  const pathnames = pathname.split("/").filter((x) => {
    // handle :id-s in routes and hide them
    if (x === ":uuid") return false;

    return x;
  });

  const getTabName = (value: string) => {
    switch (value) {
      case "my-profile":
        return "Profile";
      case "create-sa":
        return "Supplementary Agreement";
      case "access-management":
        return "access.management";
      case "approval-routes":
        return "approval.routes";
      case "tech-support":
        return "support_title";
      case "sa":
        return "";
      case "list":
        return "list.of.organizations";
      case "tasks":
        return "task";
      case "requestTask":
        return "Support tasks";
      case "my-approvals":
        return "my.approvals";
      case "my-documents":
        return "my_documents";
      case "projects":
        return "projects";
      case "archive":
        return "archive";
      default:
        return value;
    }
  };

  const isComeFromWidget = useMemo(() => {
    return (
      pathnames.includes("my-approvals") ||
      pathnames.includes("tasks") ||
      pathnames.includes("my-documents")
    );
  }, [pathnames]);

  const getVariantForId = (name: string, value: string) => {
    switch (name) {
      case "contracts":
        return `${t("contract")} #${value}`;
      case "organizations":
        return organizationName;
      // Supplementary agreements are inside /contracts route, need to check empty string
      case "":
        return `${t("supplementary.agreement")} #${value}`;
      case "task":
        return `${t("task")} #${value}`;
      case "correspondence":
        return `${t("correspondence")} #${value}`;
      case "list.of.organizations":
        return `${t("organization")} #${value}`;
      case "support_title":
        return `${t("request")} #${value}`;
      case "projects":
        return `${t("projects")} #${value}`;
      default:
        return t(name.toLowerCase());
    }
  };

  const toggleBreadCrumb = () => {
    return dispatch(setToggleBreadCrumb(!breadCrumbOpen));
  };

  return (
    <BreadcrumbsWrapper>
      <BreadcrumbTrigger opened={breadCrumbOpen} onClick={toggleBreadCrumb}>
        <Icon
          name="ChevronsRightIcon"
          color={
            breadCrumbOpen
              ? theme.palette.secondary.light
              : theme.palette.secondary.contrastText
          }
        />
      </BreadcrumbTrigger>

      {breadCrumbOpen && (
        <Fade in timeout={350}>
          <BreadcrumbPathsWrapper>
            {!pathnames.length ? (
              <Typography
                variant="body2"
                fontWeight={600}
                sx={{
                  "::first-letter": {
                    textTransform: "uppercase",
                  },
                }}
              >
                {t("home")}
              </Typography>
            ) : isComeFromWidget ? (
              <Link
                to={"/"}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "start",
                  paddingLeft: 0,
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={400}
                  sx={{
                    padding: 0,
                    "::first-letter": {
                      textTransform: "uppercase",
                    },
                  }}
                >
                  {t("home")}
                </Typography>
                <Icon name="ChevronRightIcon" className="chevron" />
              </Link>
            ) : null}

            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join("/")}`;

              const tabName = getTabName(value);

              return last ? (
                <React.Fragment key={index}>
                  {index ? (
                    <Icon name="ChevronRightIcon" className="chevron" />
                  ) : null}
                  <Typography
                    key={index}
                    variant="body2"
                    fontWeight={600}
                    sx={{
                      "::first-letter": {
                        textTransform: "uppercase",
                      },
                    }}
                  >
                    {!!Number(value) && index > 0
                      ? getVariantForId(getTabName(pathnames[index - 1]), value)
                      : t(tabName.toLowerCase())}
                  </Typography>
                </React.Fragment>
              ) : (
                value !== "sa" && (
                  <React.Fragment key={index}>
                    {index ? (
                      <Icon name="ChevronRightIcon" className="chevron" />
                    ) : null}
                    <Link to={to} key={index} style={{ padding: 0 }}>
                      <Typography
                        variant="body2"
                        fontWeight={400}
                        sx={{
                          "::first-letter": {
                            textTransform: "uppercase",
                          },
                        }}
                      >
                        {!!Number(value) && index > 0
                          ? getVariantForId(
                              getTabName(pathnames[index - 1]),
                              value
                            )
                          : t(tabName.toLowerCase())}
                      </Typography>
                    </Link>
                  </React.Fragment>
                )
              );
            })}
          </BreadcrumbPathsWrapper>
        </Fade>
      )}
    </BreadcrumbsWrapper>
  );
};
