import styled from "styled-components";
import { FLEX_COLUMN_FULL } from "ui-kit";

export const Wrapper = styled.div`
  height: 100%;
  ${FLEX_COLUMN_FULL};
  overflow: auto;
`;

export const ConversationWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(6, 0, 0, 6)};
  overflow: auto;
  width: 100%;
  height: 100%;
  .message_options {
    position: relative;
    z-index: 5;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
  }

  .message:hover .message_options {
    opacity: 1;
    pointer-events: auto;
  }

  .message_options[aria-expanded="true"] {
    opacity: 1;
    pointer-events: auto;
  }
`;
