import { axiosService } from "app";

import { getFilterParams } from "@sbm/fe-utils";
import { QueryFunctionContext } from "@tanstack/react-query";
import {
  ISendMessage,
  MessageViewResponse,
  IViewMessage,
  ConversationResponse,
} from "@types";

export const fetchMessages = async (
  { pageParam }: QueryFunctionContext,
  filter: (string | undefined)[] = [],
  sortBy = "",
  search = ""
) => {
  const page = pageParam ?? 1;
  const limit = 20;

  const dateFilters = ["filter.dateOfTheMessage"];
  const messageTypeKey = "filter.messageType";
  const filterParams = getFilterParams(filter, dateFilters, undefined, true);

  if (
    messageTypeKey in filterParams &&
    Array.isArray(filterParams[messageTypeKey])
  ) {
    filterParams[messageTypeKey] = filterParams[messageTypeKey].join("");
  }

  const params = {
    search,
    limit,
    page,
    sortBy,
    ...filterParams,
  };

  const { data } = await axiosService({
    endpoint: "messages/user",
    body: params,
  });

  return data;
};

export const fetchChatList = async ({ pageParam }: QueryFunctionContext) => {
  const page = pageParam ?? 1;
  const limit = 20;

  const params = {
    limit,
    page,
  };
  const { data } = await axiosService({
    endpoint: "messages/user/chats",
    body: params,
  });

  return data;
};

export const sendMessage = async (body: ISendMessage) => {
  const { data } = await axiosService({
    endpoint: "/messages",
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    body,
  });

  return data;
};

export const viewMessage = async (body: IViewMessage) => {
  const { data } = await axiosService({
    endpoint: `/messages/view/${body.id}`,
    method: "PUT",
    body: { messageViewed: body.view },
  });

  return data;
};

export const getMessageById = async (id: number | undefined) => {
  const { data } = await axiosService({
    endpoint: `/messages/${id}`,
  });

  return data as MessageViewResponse;
};

export const getConversation = async (
  correspondentId: number,
  conversationId: number,
  isMessageFromSystem: boolean
) => {
  const { data } = await axiosService({
    endpoint: isMessageFromSystem
      ? `/messages/conversation`
      : `/messages/conversation?correspondentId=${correspondentId}`,
  });

  return data as ConversationResponse[];
};

export const getLinks = async (
  { pageParam }: QueryFunctionContext,
  filter: (string | undefined)[] = [],
  type: string | undefined = "",
  sortBy = "",
  search = ""
) => {
  const page = pageParam ?? 1;
  const limit = 20;
  const dateFilters = ["filter.createdAt"];
  const filterParams = getFilterParams(filter, dateFilters);

  const params = {
    search,
    limit,
    page,
    sortBy,
    "filter.typeOfTheDocument": type,
    ...filterParams,
  };

  const { data } = await axiosService({
    endpoint: "/documents",
    body: params,
  });

  return data;
};

export const getMessageCorrespondents = async () => {
  const { data } = await axiosService({
    endpoint: "/filters/message-correspondents",
  });

  return data;
};

export const deleteMessage = async (body: {
  messageId: number;
  deleteForAll: boolean;
}) => {
  const { data } = await axiosService({
    endpoint: body.deleteForAll
      ? `/messages/${body.messageId}?deleteForAll=true`
      : `/messages/${body.messageId}`,
    method: "DELETE",
  });

  return data;
};
